import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Workspace } from '../types/Workspace';



interface WorkspaceState {
  workspaces: Workspace[];
  selectedWorkspace: Workspace;
}

const initialState: WorkspaceState = {
  workspaces: [],
  selectedWorkspace: {
    id: 0,
    name: '',
    description: '',
    snippet: '',
    uniCode: '',
    image: '',
  },
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaces: (state, action : PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload;
    },
    deleteWorkspace: (state, action: PayloadAction<number>) => {
      state.workspaces = state.workspaces.filter((workspace) => workspace.id !== action.payload);
    },
    addWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.workspaces.push(action.payload);
    },
    setSelectedWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.selectedWorkspace = action.payload;
      console.log('setSelectedWorkspace', action.payload);
    }
  },
});

export const { setWorkspaces, setSelectedWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;
