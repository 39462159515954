import React, { useEffect, useRef, useState } from 'react'
import Alert from '../shapes/Alert';
import LandingMenu from '../landing/LandingMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import '../../css/workspace.css'
import '../../css/WorkspaceIconPanel.css'
import '../../css/legalInfo.css'
import WorkspaceInfoIcon from '../../assets/WorkspaceInfo.svg'
import CreditCard from '../../assets/CreditCard.svg'
import Users from '../../assets/Users.svg'
import WorkspaceIconPanel from './WorkspaceIconPanel';
import UserCircle from '../auth/UserCircle';
import PdfUploader from '../shapes/PdfUploader';
import axios from 'axios';
import PdfDownloader from '../shapes/PdfDownloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../shapes/ModalComponent';
import CancelRequest from '../../assets/CancelRequest.png'
import { useParams } from 'react-router-dom';
import { Workspace } from '../../types/Workspace';


const token = localStorage.getItem('token');
const LegalInfo = () => {
  const { uniCode } = useParams();
  const trnNumberRef = useRef<HTMLInputElement>(null);
  const crNumberRef = useRef<HTMLInputElement>(null);
  const legalNameRef = useRef<HTMLInputElement>(null);
  const dateOfRegistrationRef = useRef<HTMLInputElement>(null);
  const pages = {
    'Workspace Info': [`/workspace-info/${uniCode}`, WorkspaceInfoIcon],
    'Collaborator': ['/collaborator/' + uniCode, Users],
    'Plan and Billing': ['', CreditCard],
  }
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const [articlesOfAssociationData, setArticlesOfAssociationData] = useState('');
  const [commercialRegistrationData, setCommercialRegistrationData] = useState('');
  const [vatCertificateData, setVatCertificateData] = useState('');
  const [zatkaCertificateData, setZatkaCertificateData] = useState('');
  const [chamberOfCommerceData, setChamberOfCommerceData] = useState('');
  const [gosiCertificatesData, setGosiCertificatesData] = useState('');
  const [saudizationCertificateData, setSaudizationCertificateData] = useState('');
  const [safeguardOfWagesCertificateData, setSafeguardOfWagesCertificateData] = useState('');
  const [momrahLisenseData, setMomrahLisenseData] = useState('');
  const [civilDefenceLicenseData, setCivilDefenceLicenseData] = useState('');
  const [legalName, setLegalName] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(
    {
      id: -1,
      name: '',
      snippet: '',
      description: '',
      uniCode: '',
      image: '',
    }
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(e.currentTarget);
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const legalInfo = response.data.legalInfo;
      Object.keys(legalInfo).forEach((key) => {
        let data = legalInfo[key];
        console.log(key);
        console.log(data);
        let divContainer = document.querySelector(`div[data-pdf-name="${key}"]`) as HTMLElement;
        if (divContainer) {
          divContainer.setAttribute('data-pdf', data ? data.substring(data.indexOf('/') + 1) : '');
          if (data != null) {
            divContainer.querySelector('img')?.classList.remove('inactive');
          }
        }

        //** set the state of pdf:
        if (key === 'articles_of_association') {
          setArticlesOfAssociationData(legalInfo.articles_of_association ? legalInfo.articles_of_association.substring(legalInfo.articles_of_association.indexOf('/') + 1) : '');
        } else if (key === 'commercial_registration') {
          setCommercialRegistrationData(legalInfo.commercial_registration ? legalInfo.commercial_registration.substring(legalInfo.commercial_registration.indexOf('/') + 1) : '');
        } else if (key === 'vat_certificate') {
          setVatCertificateData(legalInfo.vat_certificate ? legalInfo.vat_certificate.substring(legalInfo.vat_certificate.indexOf('/') + 1) : '');
        } else if (key === 'zatka_certificate') {
          setZatkaCertificateData(legalInfo.zatka_certificate ? legalInfo.zatka_certificate.substring(legalInfo.zatka_certificate.indexOf('/') + 1) : '');
        } else if (key === 'chamber_of_commerce') {
          setChamberOfCommerceData(legalInfo.chamber_of_commerce ? legalInfo.chamber_of_commerce.substring(legalInfo.chamber_of_commerce.indexOf('/') + 1) : '');
        } else if (key === 'gosi_certificates') {
          setGosiCertificatesData(legalInfo.gosi_certificates ? legalInfo.gosi_certificates.substring(legalInfo.gosi_certificates.indexOf('/') + 1) : '');
        } else if (key === 'saudization_certificate') {
          setSaudizationCertificateData(legalInfo.saudization_certificate ? legalInfo.saudization_certificate.substring(legalInfo.saudization_certificate.indexOf('/') + 1) : '');
        } else if (key === 'safeguard_of_wages_certificate') {
          setSafeguardOfWagesCertificateData(legalInfo.safeguard_of_wages_certificate ? legalInfo.safeguard_of_wages_certificate.substring(legalInfo.safeguard_of_wages_certificate.indexOf('/') + 1) : '');
        } else if (key === 'momrah_lisense') {
          setMomrahLisenseData(legalInfo.momrah_lisense ? legalInfo.momrah_lisense.substring(legalInfo.momrah_lisense.indexOf('/') + 1) : '');
        } else if (key === 'civil_defence_license') {
          setCivilDefenceLicenseData(legalInfo.civil_defence_license ? legalInfo.civil_defence_license.substring(legalInfo.civil_defence_license.indexOf('/') + 1) : '');
        }

        const checkCircles = document.querySelectorAll('.check-circle') as NodeListOf<HTMLElement>;
        checkCircles.forEach((checkCircle) => {
          if (checkCircle.classList.contains('text-success')) {
            // console.clear();
            const inputShape = checkCircle.closest('.input-shape') as HTMLElement;
            const deletePDF = inputShape.querySelector('.delete-pdf') as HTMLElement;
            deletePDF.classList.remove('inactive');

          }
        });
      })
      setShowAlert(true);
      setAlertBody('Legal info saved successfully');
      setAlertStatus('success');
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setLoading(false);
    }
  }

  const fetchLegalInfo = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/workspace-legal-info/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const legalInfo = response.data.legalInfo;
      console.log(response.data);
      setSelectedWorkspace(response.data.selectedWorkspace);
      if (legalInfo && trnNumberRef.current) {
        trnNumberRef.current.value = legalInfo.trn_number ?? '';
      }

      if (legalInfo && crNumberRef.current) {
        crNumberRef.current.value = legalInfo.cr_number ?? '';
      }

      if (legalInfo && legalNameRef.current) {
        legalNameRef.current.value = legalInfo.legal_name ?? '';
      }

      if (legalInfo && dateOfRegistrationRef.current) {
        dateOfRegistrationRef.current.value = legalInfo.date_of_registration;
      }
      // console.clear();
      console.log(response.data.selectedWorkspace);
      setArticlesOfAssociationData(legalInfo && legalInfo.articles_of_association ? legalInfo.articles_of_association.substring(legalInfo.articles_of_association.indexOf('/') + 1) : '');
      setCommercialRegistrationData(legalInfo && legalInfo.commercial_registration ? legalInfo.commercial_registration.substring(legalInfo.commercial_registration.indexOf('/') + 1) : '');
      setVatCertificateData(legalInfo && legalInfo.vat_certificate ? legalInfo.vat_certificate.substring(legalInfo.vat_certificate.indexOf('/') + 1) : '');
      setZatkaCertificateData(legalInfo && legalInfo.zatka_certificate ? legalInfo.zatka_certificate.substring(legalInfo.zatka_certificate.indexOf('/') + 1) : '');
      setChamberOfCommerceData(legalInfo && legalInfo.chamber_of_commerce ? legalInfo.chamber_of_commerce.substring(legalInfo.chamber_of_commerce.indexOf('/') + 1) : '');
      setGosiCertificatesData(legalInfo && legalInfo.gosi_certificates ? legalInfo.gosi_certificates.substring(legalInfo.gosi_certificates.indexOf('/') + 1) : '');
      setSaudizationCertificateData(legalInfo && legalInfo.saudization_certificate ? legalInfo.saudization_certificate.substring(legalInfo.saudization_certificate.indexOf('/') + 1) : '');
      setSafeguardOfWagesCertificateData(legalInfo && legalInfo.safeguard_of_wages_certificate ? legalInfo.safeguard_of_wages_certificate.substring(legalInfo.safeguard_of_wages_certificate.indexOf('/') + 1) : '');
      setMomrahLisenseData(legalInfo && legalInfo.momrah_lisense ? legalInfo.momrah_lisense.substring(legalInfo.momrah_lisense.indexOf('/') + 1) : '');
      setCivilDefenceLicenseData(legalInfo && legalInfo.civil_defence_license ? legalInfo.civil_defence_license.substring(legalInfo.civil_defence_license.indexOf('/') + 1) : '');
      setLegalName(legalInfo && legalInfo.legal_name ? legalInfo.legal_name.substring(legalInfo.legal_name.indexOf('/') + 1) : '');

      const articlesOfAssociation = document.querySelector('input[name="articles_of_association"]') as HTMLInputElement;
      // if (articlesOfAssociation) {
      //   articlesOfAssociation.value = legalInfo.articles_of_association;
      // }

      console.log('Legal Info:', response.data);
    } catch (error) {
      console.error('Error fetching legal info:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleDeletePDF = async (e: React.MouseEvent<HTMLElement>, action: string, closeModal: () => void) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/delete-legal-pdf/${uniCode}`;
    try {
      const data = new FormData();
      data.append('pdf_type', action);

      const pdfValue = document.querySelector(`div[data-pdf-name="${action}"]`)?.getAttribute('data-pdf') || '';
      data.append('pdf', pdfValue.substring(pdfValue.indexOf('/') + 1));

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShowAlert(true);
      setAlertBody('Legal info deleted successfully');
      setAlertStatus('success');
      console.log('File deleted successfully:', response.data);

      const times = document.querySelector(`div[data-pdf-name="${action}"]`) as HTMLElement;
      const downloadIcon = times.closest('.input-shape')?.querySelector('.download-icon') as HTMLElement;
      console.log(downloadIcon);
      if (times) {
        const inputShape = times.closest('.input-shape') as HTMLElement;
        const checkCircle = inputShape.querySelector('.check-circle') as HTMLElement;

        times.classList.add('inactive');
        checkCircle.classList.add('inactive');

        checkCircle.classList.remove('text-success');
      }
      downloadIcon.classList.add('inactive');

      // Close the modal after successful file deletion
      closeModal();

    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };

  useEffect(() => {
    // console.clear();
    console.info("selectedWorkspace - LegalInfo.tsx: ", selectedWorkspace);
  }, [selectedWorkspace]);

  useEffect(() => {
    fetchLegalInfo();
  }, []);

  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <LandingMenu pages={pages} hidePopup={true} hidePanelContents={false} pageProp="WORKSPACE INFO" selectedWorkspace={selectedWorkspace ? selectedWorkspace :
        {
          id: 123,
          name: '',
          snippet: '',
          description: '',
          uniCode: '',
          image: '',
        }
      } />
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Workspace info</h3>
            <p>Legal Info and Files</p>
          </div>
          <UserCircle />
        </div>

        <div id='content-container' className="">
          {
            <>
              <p className={`bold-text ${loading ? '' : 'overnight'}`} style={
                {
                  'color': '#94ddf3',
                  'display': 'block',
                  'fontSize': '2rem',
                  'position': 'absolute',
                  'marginBottom': '.5rem'
                }}>
                Loading...
              </p>
            </>
          }

          {
            uniCode
              ?
              <WorkspaceIconPanel page="files" uniCode={uniCode} />
              :
              ''
          }
          <div className="form-box rounded-radius w-50 m-t-5 pd-1 right-panel">
            <form id='workspace-form' method="post" onSubmit={handleSubmit}>
              <input type="hidden" name="workspace_uni_code" value={uniCode} />
              <div className="flex-container">
                <div className="vertical-flex legal-info-column">
                  <div className="input-shape">
                    <p className='shape'>ARTICLES OF ASSOCIATION</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${articlesOfAssociationData}`} fileName="test123.pdf" file={articlesOfAssociationData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='articles_of_association' />
                    <div className='delete-pdf-container' data-pdf-name='articles_of_association' data-pdf={articlesOfAssociationData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${articlesOfAssociationData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'articles_of_association', closeModal)} // Passing closeModal here
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${articlesOfAssociationData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>COMMERCIAL REGISTRATION</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${commercialRegistrationData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={commercialRegistrationData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='commercial_registration' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${commercialRegistrationData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='commercial_registration' data-pdf={commercialRegistrationData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${commercialRegistrationData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'commercial_registration', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${commercialRegistrationData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>VAT CERTIFICATE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${vatCertificateData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={vatCertificateData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='vat_certificate' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${vatCertificateData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='vat_certificate' data-pdf={vatCertificateData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${vatCertificateData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'vat_certificate', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${vatCertificateData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>ZATCA CERTIFICATE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${zatkaCertificateData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={zatkaCertificateData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='zatka_certificate' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${zatkaCertificateData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='zatka_certificate' data-pdf={zatkaCertificateData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${zatkaCertificateData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'zatka_certificate', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${zatkaCertificateData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>CHAMBER OF COMMERCE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${chamberOfCommerceData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={chamberOfCommerceData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='chamber_of_commerce' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${chamberOfCommerceData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='chamber_of_commerce' data-pdf={chamberOfCommerceData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${chamberOfCommerceData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'chamber_of_commerce', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${chamberOfCommerceData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>GOSI CERTIFICATES</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${gosiCertificatesData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={gosiCertificatesData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='gosi_certificate' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${gosiCertificatesData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='gosi_certificate' data-pdf={gosiCertificatesData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${gosiCertificatesData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'gosi_certificate', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${gosiCertificatesData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>SAUDIZATION CERTIFICATE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${saudizationCertificateData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={saudizationCertificateData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='saudization_certificate' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${saudizationCertificateData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='saudization_certificate' data-pdf={saudizationCertificateData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${saudizationCertificateData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'saudization_certificate', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${saudizationCertificateData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>SAFEGUARD OF WAGES CERTIFICATE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${safeguardOfWagesCertificateData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={safeguardOfWagesCertificateData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='safeguard_of_wages_certificate' />
                    {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className={`check-circle m-r-1 text-danger ${safeguardOfWagesCertificateData !== '' ? 'clickable pointer' : 'inactive'}`} /> */}
                    <div className='delete-pdf-container' data-pdf-name='safeguard_of_wages_certificate' data-pdf={safeguardOfWagesCertificateData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${safeguardOfWagesCertificateData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'safeguard_of_wages_certificate', closeModal)}
                        actionName='articles_of_association'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${safeguardOfWagesCertificateData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>MOMRAH LISENSE (BALADIYA)</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${momrahLisenseData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={momrahLisenseData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='momrah_lisense' />
                    <div className='delete-pdf-container' data-pdf-name='momrah_lisense' data-pdf={momrahLisenseData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${momrahLisenseData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'momrah_lisense', closeModal)}
                        actionName='momrah_lisense'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${momrahLisenseData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                  <div className="input-shape">
                    <p className='shape'>CIVIL DEFENCE LICENSE</p>
                    <PdfDownloader url={`${process.env.REACT_APP_API_URL}/download/pdf/${civilDefenceLicenseData}`} fileName="CeFAPW8jWmN1RfIZ4HJzhWsp507e1fC5BLektca8.pdf" file={civilDefenceLicenseData} />
                    <PdfUploader url={`${process.env.REACT_APP_Laravel_API_URL_STRUCTURE}storage/${process.env.REACT_APP_API_VERSION}/legal-pdf-upload`} name='civil_defence_license' />
                    <div className='delete-pdf-container' data-pdf-name='civil_defence_license' data-pdf={civilDefenceLicenseData}>
                      <ModalComponent
                        logoutStyle='from-menu'
                        content={CancelRequest}
                        classProp={`m-r-1 role_image delete-pdf clickable pointer w-24px ${civilDefenceLicenseData !== '' ? '' : 'inactive'} `}
                        message={'Are you sure you want to cancel the invitation?'}
                        action={(e, action, closeModal) => handleDeletePDF(e, 'civil_defence_license', closeModal)}
                        actionName='civil_defence_license'
                        label="Delete PDF"
                      />
                    </div>
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className={`check-circle m-r-1 ${civilDefenceLicenseData !== '' ? 'text-success' : 'inactive'}`} />
                  </div>
                </div>
                <div className="vertical-flex legal-info-column">
                  <input type="text" name="legal_name" ref={legalNameRef} className='input-shape' placeholder='Legal name' />
                  <input type="date" name='date_of_registration' ref={dateOfRegistrationRef} className='date-shape' />
                  <input type="text" name='cr_number' ref={crNumberRef} className='input-shape' placeholder='CR #' />
                  <input type="text" name='trn_number' ref={trnNumberRef} className='input-shape' placeholder='TRN #' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                  <input type="text" className='input-shape' readOnly placeholder='' />
                </div>
              </div>
              <input type="submit" value="Save" className='btn btn-dark m-t-1 float-right sejel-button' />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalInfo