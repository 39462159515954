import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../css/PLTable.css';

interface FinancialData {
  revenues: { [year: string]: number };
  expenses: {
    [year: string]: {
      [account: string]: number;
    };
  };
}

const PLTable: React.FC = () => {
  const [data, setData] = useState<FinancialData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/pl-data`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });
        const fetchedData = response.data;

        // Override 2021 data
        fetchedData.revenues["2021"] = 4836106;
        fetchedData.expenses["2021"] = {
          "COST OF SALES": -2849249,
          "PERSONNEL EXPENSES": -1035173,
          "GENERAL & ADMIN EXPENSES": -230091,
          "OTHER EXPENSES": -300000,
          "DEPRECIATION": -200000 // Example depreciation value
        };

        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching P&L data:', error);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const years = Array.from(new Set([
    ...Object.keys(data.revenues),
    ...Object.keys(data.expenses)
  ])).sort();

  const accounts: { [account: string]: { [year: string]: number } } = {};

  Object.keys(data.expenses).forEach((year) => {
    const yearData = data.expenses[year];
    Object.keys(yearData).forEach((account) => {
      if (!accounts[account]) {
        accounts[account] = {};
      }
      accounts[account][year] = yearData[account];
    });
  });

  const getGrossProfit = (year: string): number => {
    const revenue = data.revenues[year] ?? 0;
    const costOfSales = accounts['COST OF SALES']?.[year] ?? 0;
    return revenue + costOfSales;
  };

  const getEBITDA = (year: string): number => {
    const grossProfit = getGrossProfit(year);
    const personnelExpenses = accounts['PERSONNEL EXPENSES']?.[year] ?? 0;
    const generalAdminExpenses = accounts['GENERAL & ADMIN EXPENSES']?.[year] ?? 0;
    return grossProfit + personnelExpenses + generalAdminExpenses;
  };

  const getEBITDAMargin = (year: string): number => {
    const revenue = data.revenues[year] ?? 0;
    const ebitda = getEBITDA(year);
    return revenue ? (ebitda / revenue) * 100 : 0;
  };

  const getEBIT = (year: string): number => {
    const ebitda = getEBITDA(year);
    const depreciationExpenses = accounts['DEPRECIATION']?.[year] ?? 0;
    return ebitda + depreciationExpenses;
  };

  const getEBITMargin = (year: string): number => {
    const revenue = data.revenues[year] ?? 0;
    const ebit = getEBIT(year);
    return revenue ? (ebit / revenue) * 100 : 0;
  };

  const getEBT = (year: string): number => {
    const ebit = getEBIT(year);
    const interestRateExpenses = accounts['INTEREST RATE']?.[year] ?? 0;
    return ebit + interestRateExpenses;
  };

  const getEBTMargin = (year: string): number => {
    const revenue = data.revenues[year] ?? 0;
    const ebt = getEBT(year);
    return revenue ? (ebt / revenue) * 100 : 0;
  };

  const getRemainingExpenses = (year: string): number => {
    const excludedAccounts = ['COST OF SALES', 'PERSONNEL EXPENSES', 'GENERAL & ADMIN EXPENSES', 'INTEREST RATE', 'TAX'];
    return Object.keys(accounts).reduce((sum, account) => {
      if (!excludedAccounts.includes(account)) {
        sum += accounts[account]?.[year] ?? 0;
      }
      return sum;
    }, 0);
  };

  return (
    <div className="pl-table-container">
      <table className="pl-table">
        <thead>
          <tr>
            <th>PROFIT & LOSS</th>
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>REVENUE</td>
            {years.map((year) => (
              <td key={year}>{Math.round(data.revenues[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>COGS</td>
            {years.map((year) => (
              <td key={year}>{Math.round(accounts['COST OF SALES']?.[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT</td>
            {years.map((year) => (
              <td key={year}>{Math.round(getGrossProfit(year)).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT MARGIN</td>
            {years.map((year) => {
              const revenue = data.revenues[year] ?? 0;
              const grossProfit = getGrossProfit(year);
              const grossProfitMargin = revenue ? (grossProfit / revenue) * 100 : 0;
              return <td key={year}>{grossProfitMargin.toFixed(2)}%</td>;
            })}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>PERSONNEL EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{Math.round(accounts['PERSONNEL EXPENSES']?.[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>G&A EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{Math.round(accounts['GENERAL & ADMIN EXPENSES']?.[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA</td>
            {years.map((year) => (
              <td key={year}>{Math.round(getEBITDA(year)).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA MARGIN</td>
            {years.map((year) => (
              <td key={year}>{getEBITDAMargin(year).toFixed(2)}%</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>DEPRECIATION</td>
            {years.map((year) => (
              <td key={year}>{Math.round(accounts['DEPRECIATION']?.[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT</td>
            {years.map((year) => (
              <td key={year}>{Math.round(getEBIT(year)).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{getEBITMargin(year).toFixed(2)}%</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>INTEREST CHARGES</td>
            {years.map((year) => (
              <td key={year}>{Math.round(accounts['INTEREST RATE']?.[year] ?? 0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBT</td>
            {years.map((year) => (
              <td key={year}>{Math.round(getEBT(year)).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>EBT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{getEBTMargin(year).toFixed(2)}%</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>TAX ON PROFIT</td>
            {years.map((year) => (
              <td key={year}>{Math.round(0).toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT</td>
            {years.map((year) => (
              <td key={year}>{Math.round(getEBT(year)).toLocaleString()}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PLTable;
