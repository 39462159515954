import React, { useEffect } from 'react';
import '../../css/FinancialStatements.css'; // Ensure you create and style this CSS file
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const financialData = {
  growthInRevenue: {
    "2020": "0.00%",
    "2021": "11.27%",
    "2022": "128.50%",
    "2023": "77.76%",
    "2024": "-53.99%",
  },
  revenueAVI: {
    "2020": 0,
    "2021": 0,
    "2022": 11050544,
    "2023": 19643941,
    "2024": 9037709,
  },
  vat: {
    "2020": 0,
    "2021": 0,
    "2022": 1441375,
    "2023": 2562253,
    "2024": 1171396,
  },
  revenueAFV: {
    "2020": 4346322,
    "2021": 4836106,
    "2022": 9609167,
    "2023": 17081687,
    "2024": 7858878,
  },
  costOfRevenue: {
    "2020": -2648949,
    "2021": -2849249,
    "2022": -3756791,
    "2023": -11838887,
    "2024": -4995938,
  },
  grossProfit: {
    "2020": 1697373,
    "2021": 1986857,
    "2022": 5852376,
    "2023": 5242800,
    "2024": 2862940,
  },
  grossProfitMargin: {
    "2020": "39.05%",
    "2021": "41.08%",
    "2022": "52.96%",
    "2023": "26.69%",
    "2024": "31.68%",
  },
  personnelExpenses: {
    "2020": -837922,
    "2021": -1035173,
    "2022": -1571477,
    "2023": -2752900,
    "2024": -1407278,
  },
  generalAndAdminExpenses: {
    "2020": -565228,
    "2021": -230091,
    "2022": -634648,
    "2023": -1171553,
    "2024": -467361,
  },
  ebitda: {
    "2020": 294223,
    "2021": 721593,
    "2022": 5217728,
    "2023": 4071247,
    "2024": 2395579,
  },
  ebitdaMargin: {
    "2020": "6.77%",
    "2021": "14.92%",
    "2022": "47.22%",
    "2023": "20.73%",
    "2024": "26.51%",
  },
  depreciation: {
    "2020": -22321,
    "2021": -15766,
    "2022": -15933,
    "2023": -16453,
    "2024": -5229,
  },
  ebit: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794,
    "2024": 2390343,
  },
  ebitMargin: {
    "2020": "6.26%",
    "2021": "14.59%",
    "2022": "47.07%",
    "2023": "20.64%",
    "2024": "26.45%",
  },
  interest: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 0,
    "2024": -35007,
  },
  ebt: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794,
    "2024": 2355343,
  },
  ebtMargin: {
    "2020": "6.26%",
    "2021": "14.59%",
    "2022": "47.07%",
    "2023": "20.64%",
    "2024": "26.06%",
  },
  zakat: {
    "2020": -10501,
    "2021": -30888,
    "2022": -156947,
    "2023": -277297,
    "2024": 0,
  },
  otherIncomeExpenses: {
    "2020": 0,
    "2021": 0,
    "2022": 10501,
    "2023": 30888,
    "2024": 0,
  },
  netProfit: {
    "2020": 261401,
    "2021": 674939,
    "2022": 5055350,
    "2023": 3808385,
    "2024": 2355343,
  },
  netProfitMargin: {
    "2020": "6.01%",
    "2021": "13.96%",
    "2022": "45.75%",
    "2023": "19.39%",
    "2024": "26.06%",
  },
  assets: {
    "2020": 1010610,
    "2021": 2316114,
    "2022": 6965959,
    "2023": 11976111,
  },
  nonCurrentAssets: {
    "2020": 53811,
    "2021": 38045,
    "2022": 22945,
    "2023": 9094,
  },
  propertyPlantEquipment: {
    "2020": 53811,
    "2021": 38045,
    "2022": 22945,
    "2023": 9094,
  },
  currentAssets: {
    "2020": 956799,
    "2021": 2278069,
    "2022": 6943014,
    "2023": 11967017,
  },
  relatedParties: {
    "2020": 147863,
    "2021": 214291,
    "2022": 214291,
    "2023": 607791,
  },
  accountsReceivables: {
    "2020": 579259,
    "2021": 1746837,
    "2022": 6636767,
    "2023": 10491887,
  },
  otherReceivables: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 96733,
  },
  cashBanks: {
    "2020": 229677,
    "2021": 316941,
    "2022": 91956,
    "2023": 770606,
  },
  shareholdersEquityLiabilities: {
    "2020": 1010610,
    "2021": 2316114,
    "2022": 6965959,
    "2023": 11976111,
  },
  totalShareholdersEquity: {
    "2020": 361401,
    "2021": 1036340,
    "2022": 6091690,
    "2023": 9900075,
  },
  capitalCommonShares: {
    "2020": 100000,
    "2021": 100000,
    "2022": 100000,
    "2023": 100000,
  },
  capitalReserves: {
    "2020": 26140,
    "2021": 26140,
    "2022": 26140,
    "2023": 26140,
  },
  retainedEarnings: {
    "2020": 235261,
    "2021": 910200,
    "2022": 5965550,
    "2023": 9773935,
  },
  profitBroughtForward: {
    "2020": -26140,
    "2021": 235261,
    "2022": 910200,
    "2023": 5965550,
  },
  netProfitForTheYear: {
    "2020": 261401,
    "2021": 674939,
    "2022": 5055350,
    "2023": 3808385,
  },
  dividends: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 0,
  },
  liabilities: {
    "2020": 649209,
    "2021": 1279774,
    "2022": 874269,
    "2023": 2076036,
  },
  nonCurrentLiabilities: {
    "2020": 45041,
    "2021": 144827,
    "2022": 144827,
    "2023": 999976,
  },
  loan: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 999976,
  },
  endOfServiceIndemnity: {
    "2020": 45041,
    "2021": 144827,
    "2022": 144827,
    "2023": 144827,
  },
  currentLiabilities: {
    "2020": 604168,
    "2021": 1134947,
    "2022": 729442,
    "2023": 1076060,
  },
  accountsPayables: {
    "2020": 147956,
    "2021": 431768,
    "2022": 359467,
    "2023": 641360,
  },
  otherPayables: {
    "2020": 0,
    "2021": 0,
    "2022": 0,
    "2023": 82,
  },
  relatedPartiesLiabilities: {
    "2020": 445711,
    "2021": 661790,
    "2022": 182141,
    "2023": 374,
  },
  zakatPayables: {
    "2020": 10501,
    "2021": 41389,
    "2022": 187835,
    "2023": 434244,
  },
  netProfitCashFlow: {
    "2020": 271902,
    "2021": 705827,
    "2022": 5201796,
    "2023": 4054794,
  },
  addBackDepreciation: {
    "2020": 22321,
    "2021": 15766,
    "2022": 15933,
    "2023": 16453,
  },
  addBackEOSI: {
    "2020": 45041,
    "2021": 99786,
    "2022": 0,
    "2023": 855149,
  },
  changeInPayables: {
    "2020": 147956,
    "2021": 283812,
    "2022": -72301,
    "2023": 281976,
  },
  changeInReceivables: {
    "2020": -579259,
    "2021": -1167578,
    "2022": -4889930,
    "2023": -3951853,
  },
  netCashFromOperations: {
    "2020": -92039,
    "2021": -62387,
    "2022": 255497,
    "2023": 1256518,
  },
  investmentsInPPE: {
    "2020": -76132,
    "2021": 0,
    "2022": -833,
    "2023": -2601,
  },
  netCashFromInvestments: {
    "2020": -76132,
    "2021": 0,
    "2022": -833,
    "2023": -2601,
  },
  changeInCapital: {
    "2020": 100000,
    "2021": 0,
    "2022": 0,
    "2023": 0,
  },
  changeInRelatedPartiesCashFlow: {
    "2020": 297848,
    "2021": 149651,
    "2022": -479649,
    "2023": -575267,
  },
  netCashFromFinancing: {
    "2020": 397848,
    "2021": 149651,
    "2022": -479649,
    "2023": -575267,
  },
  netCashFlow: {
    "2020": 229677,
    "2021": 87264,
    "2022": -224985,
    "2023": 678650,
  },
  cumulativeCashFlow: {
    "2020": 229677,
    "2021": 316941,
    "2022": 91956,
    "2023": 770606,
  },
};

const FinancialStatements: React.FC = () => {
  const query = useParams();
  const uniCode = query.uniCode;
  const selectedWorkspace = useSelector((state: RootState) => state.workspaceState.selectedWorkspace);
  const years = ["2020", "2021", "2022", "2023", "2024"];

  const formatNumber = (value: any) => {
    return new Intl.NumberFormat('en-US').format(value);
  };
  const fetchInitialData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-workspace-data/${uniCode}`;
      const response = await axios.get(url);
      console.log(response.data);

    } catch (error) {
      console.error('Error fetching financial data:', error);
    }
  }
  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <div className="table-container">
      <h1>Financial Statements - {selectedWorkspace.name}, IN SAR</h1>
      <table className="financial-statements-table">
        <thead>
          <tr>
            <th>FINANCIAL STATEMENTS - {selectedWorkspace.name}, IN SAR</th>
            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="report-name-row"><td colSpan={years.length + 1}><b>PROFIT / LOSS STATEMENT</b></td></tr>
          <tr>
            <td>GROWTH IN REVENUE</td>
            {years.map((year) => (
              <td key={year}>{financialData.growthInRevenue[year]}</td>
            ))}
          </tr>
          <tr>
            <td>REVENUE AVI</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.revenueAVI[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>VAT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.vat[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>REVENUE AFV</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.revenueAFV[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>COST OF REVENUE</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.costOfRevenue[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT (LOSS)</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.grossProfit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GROSS PROFIT (LOSS) MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.grossProfitMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>PERSONNEL EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.personnelExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>GENERAL AND ADMINISTRATIVE EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.generalAndAdminExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebitda[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBITDA MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebitdaMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>DEPRECIATION</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.depreciation[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBIT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebitMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>INTEREST</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.interest[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.ebt[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>EBT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.ebtMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>ZAKAT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.zakat[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>OTHER INCOME / EXPENSES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.otherIncomeExpenses[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netProfit[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT MARGIN</td>
            {years.map((year) => (
              <td key={year}>{financialData.netProfitMargin[year]}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr className="report-name-row"><td colSpan={years.length + 1}><b>BALANCE SHEET</b></td></tr>
          <tr>
            <td>ASSETS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.assets[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NON-CURRENT ASSETS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.nonCurrentAssets[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>PROPERTY, PLANT & EQUIPMENT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.propertyPlantEquipment[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CURRENT ASSETS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.currentAssets[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>RELATED PARTIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.relatedParties[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>ACCOUNTS RECEIVABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.accountsReceivables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>OTHER RECEIVABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.otherReceivables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CASH & BANKS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.cashBanks[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>SHAREHOLDERS' EQUITY & LIABILITIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.shareholdersEquityLiabilities[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>TOTAL SHAREHOLDERS' EQUITY</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.totalShareholdersEquity[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CAPITAL - COMMON SHARES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.capitalCommonShares[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CAPITAL RESERVES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.capitalReserves[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>RETAINED EARNINGS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.retainedEarnings[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>PROFIT BROUGHT FORWARD</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.profitBroughtForward[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET PROFIT FOR THE YEAR</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netProfitForTheYear[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>DIVIDENDS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.dividends[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>LIABILITIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.liabilities[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NON-CURRENT LIABILITIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.nonCurrentLiabilities[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>LOAN</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.loan[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>END OF SERVICE INDEMNITY</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.endOfServiceIndemnity[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CURRENT LIABILITIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.currentLiabilities[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>ACCOUNTS PAYABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.accountsPayables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>OTHER PAYABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.otherPayables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>RELATED PARTIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.relatedPartiesLiabilities[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>ZAKAT PAYABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.zakatPayables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr className="report-name-row"><td colSpan={years.length + 1}><b>CASH FLOW</b></td></tr>
          <tr>
            <td>NET PROFIT</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netProfitCashFlow[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>ADD BACK DEPRECIATION</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.addBackDepreciation[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>ADD BACK EOSI</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.addBackEOSI[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CHANGE IN PAYABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.changeInPayables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CHANGE IN RECEIVABLES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.changeInReceivables[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET CASH FROM OPERATIONS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netCashFromOperations[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>INVESTMENTS IN PPE</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.investmentsInPPE[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET CASH FROM INVESTMENTS</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netCashFromInvestments[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>CHANGE IN CAPITAL</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.changeInCapital[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CHANGE IN RELATED PARTIES</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.changeInRelatedPartiesCashFlow[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>NET CASH FROM FINANCING</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netCashFromFinancing[year] ?? 0)}</td>
            ))}
          </tr>
          <tr><td colSpan={years.length + 1}>&nbsp;</td></tr>
          <tr>
            <td>NET CASH FLOW</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.netCashFlow[year] ?? 0)}</td>
            ))}
          </tr>
          <tr>
            <td>CUMULATIVE CASH FLOW</td>
            {years.map((year) => (
              <td key={year}>{formatNumber(financialData.cumulativeCashFlow[year] ?? 0)}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinancialStatements;
