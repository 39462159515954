import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Alert from '../shapes/Alert';
import '../../css/invoices.css'
import '../../css/tabs.css'
import { addCommaAndSeparator } from '../../utilities/helper';
import PDF from '../../assets/PDF.svg'
// import Expand from './../../assets/Expand.svg'
import Expand from './../../assets/Expand4.svg'
import Note from './../../assets/Note.svg'
import InvoiceView from './InvoiceView';
import InvoiceExpandPopup from './InvoiceExpandPopup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
interface Invoice {
  id: number;
  client_name: string;
  invoice_number: string;
  sale_type: string;
  issue_date: string;
  due_date: string;
  note: string;
  uni_code: string;
  profile_image: string;
  user_name: string;
}

const Invoices = () => {
  const param = useParams<{ uniCode: string }>();
  const uniCode = param.uniCode;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [totalTaxableAmount, setTotalTatotalTaxableAmount] = useState();
  const [totalVAT, setTotalVat] = useState();
  const [showInvoiceExpandPopup, setShowInvoiceExpandPopup] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/invoices/${uniCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      setInvoices(response.data.invoices)
      setTotalTatotalTaxableAmount(response.data.totalTaxableAmount)
      setTotalVat(response.data.totalVAT)
      console.log(response.data);
      setIsLoading(false)
    } catch (error) {
      console.log('Error fetching invoices:', error);
    }
  }
  useEffect(() => {
    if (counter < 1)
      fetchData();
    setCounter(counter + 1);
  }, []);

  const handleExpandPopup = () => {
    showInvoiceExpandPopup ? setShowInvoiceExpandPopup(false) : setShowInvoiceExpandPopup(true);
    console.log('expand');
  }

  const handleTrNavigate = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const tr = target.closest('tr') as HTMLTableRowElement;

    if (!target.classList.contains('prevent-navigation')) {
      console.log('/' + uniCode + '/invoice/' + tr.getAttribute('data-unicode'))
      navigate('/' + uniCode + '/invoice/' + tr.getAttribute('data-unicode'));
    }
  }

  return (
    <>
      {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      <table id='invoices-table'>
        <thead className='bg-grassy'>
          <tr>
            <th className='half-cell'></th>
            <th className='customer-cell'>CUSTOMER</th>
            <th className='normal-cell'>INVOICE #</th>
            <th className='normal-cell'>INVOICE TYPE</th>
            <th className='normal-cell'>INVOICE DATE</th>
            <th className='normal-cell'>DUE DATE</th>
            <th className='normal-cell'>STATUS</th>
            <th className='normal-cell'>TTA</th>
            <th className='normal-cell'>VAT</th>
            <th className='normal-cell'>TAV</th>
            <th className='half-cell'></th>
            <th className='half-cell'></th>
            <th className='half-cell'></th>
          </tr>
        </thead>
        <tbody>
          {
            invoices.map((invoice, i) => {
              return (
                <tr key={invoice.id} className='pointer' data-unicode={invoice.uni_code} onClick={handleTrNavigate}>
                  <td className='expand-td'>
                    <img src={Expand} onClick={handleExpandPopup} />
                  </td>
                  <td>{invoice.client_name}</td>
                  <td>{invoice.invoice_number}</td>
                  <td>{invoice.sale_type}</td>
                  <td>{invoice.issue_date}</td>
                  <td>{invoice.due_date}</td>
                  <td>DUE</td>
                  <td>{totalTaxableAmount ? addCommaAndSeparator(totalTaxableAmount[i]) : ''}</td>
                  <td>{totalVAT ? addCommaAndSeparator(totalVAT[i]) : ''}</td>
                  <td>{totalVAT && totalTaxableAmount ? addCommaAndSeparator(totalVAT[i] + totalTaxableAmount[i]) : ''}</td>
                  <td className='prevent-navigation'>
                    <img src={Note} className='download-pdf note pointer clickable prevent-navigation' alt="note" />
                    {/* {invoice.note} */}
                  </td>
                  <td className='prevent-navigation'>
                    <img src={PDF} alt="pdf-icon" className='download-pdf pointer pdf pointer clickable prevent-navigation' />
                  </td>
                  <td className='customer-circle-td icon-container'>
                    {
                      invoice.profile_image ?
                        <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + invoice.profile_image} alt="profile" className='customer-circle-image' />
                        :
                        <div className="customer-circle">
                          {invoice.user_name ? invoice.user_name.substring(0, 2).toUpperCase() : ''}
                        </div>
                    }
                    <span className="hover-label">{invoice.user_name}</span>
                    {/* <img src={PDF} alt="pdf-icon" className='download-pdf pointer' /> */}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {isLoading ? <p className="bold-text" style={
        {
          'color': '#94ddf3',
          'display': 'inline-block',
          'marginLeft': '1rem',
          'fontSize': '2rem',
          // 'position': 'fixed',
          // 'top': '1rem',
        }}>
        Loading...
      </p> : ""}
      <Link className='create-invoice create-button clickable' to={`/create-invoice/${uniCode}`}>Create Invoice</Link>
      {/* <InvoiceExpandPopup showInvoiceExpandPopup={showInvoiceExpandPopup} /> */}
    </>
  )
}

export default React.memo(Invoices)