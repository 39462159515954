import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../../css/Logout.css'; // Import the CSS file for modal styling
Modal.setAppElement('#root'); // Ensure this is set for accessibility

interface Props {
  logoutStyle?: string;
  content: string;
  classProp: string;
  message: string;
  actionName: string;
  label: string;
  action: (e: React.MouseEvent<HTMLElement>, action: string, closeModal: () => void) => Promise<void>;
  showModal?: boolean;
}

const ModalComponent: React.FC<Props> = ({ logoutStyle, content, classProp, message, action, actionName, label, showModal }) => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  useEffect(() => {
    // alert(showModal)
    if (showModal) {
      openModal();
      showModal = false;
    } else {
      closeModal();
    }
  }, [showModal]);
  return (
    <div>
      <div className="icon-container">
        <img src={content} onClick={openModal} className={classProp} />
        <span className="hover-label">{label}</span>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Logout Confirmation"
        className="logout-modal"
        overlayClassName="overlay"
      >
        <h2>Confirm</h2>
        <p>{message}</p>
        <div className="modal-buttons">
        <button onClick={(e) => action(e, actionName, closeModal)} className="confirm-button">Yes</button>
          <button onClick={closeModal} className="cancel-button">No</button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;
