import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import '../css/acceptInvitation.css'
import { Link } from 'react-router-dom';
import SEJELLogo from '../assets/png_trns_logo_white 1.svg';

const AcceptInvitation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tempToken = searchParams.get('tempToken');

  const acceptInvitation = async () => {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/accept-invitation-to-new-workspace`;
    const data = {
      // get the value of window.location of key tempToken
      tempToken: tempToken,
    }
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate('/')
    } catch (error) {
      console.log('Error accepting invitation:', error);
      const pTag = document.getElementById('accept-text');
      if (pTag) {
        pTag.innerText = 'Error accepting the invitation';
        pTag.style.color = '#F00';
      }
    } finally {
    }

  }

  useEffect(() => {
    acceptInvitation()
  }, []);
  return (
    <div className='login-page'>
      <div className="flex-container">
        <div className="flex-2-2 left-side">
          <div className="login-container">
            <div className="login-box accept-box">
              <h1 id='accept-text' className='accept-text'>Accepting the invitation...</h1>
            </div>
          </div>
        </div>
        <div className="flex-2-2">
          <img src={SEJELLogo} alt="logo" />
        </div>
      </div>
    </div >
  )
}

export default AcceptInvitation