// src/components/Logout.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { logout } from '../../services/api';
import '../../css/Logout.css'; // Import the CSS file for modal styling
import { useDispatch } from 'react-redux';
import { removeUserFromRedux } from '../../features/users/UserSlice';
import { logoutAction } from '../../actions/authActions';
import axios from 'axios';

Modal.setAppElement('#root'); // Ensure this is set for accessibility

interface LogoutProps {
    logoutStyle?: string;
}

const Logout: React.FC<LogoutProps> = ({ logoutStyle }) => {
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const dispatch = useDispatch();
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleLogout = () => {
        try {
            dispatch(logoutAction());
            axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            localStorage.removeItem('token'); // Remove token from local storage
            localStorage.removeItem('role'); // Remove token from local storage
            localStorage.removeItem('user'); // Remove token from local storage
            localStorage.removeItem('selectedWorkspace'); // Remove token from local storage
            navigate('/login'); // Redirect to login page
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const confirmLogout = () => {
        handleLogout();
        closeModal();
    };

    return (
        <div>
            <button onClick={openModal} id='logout' className={logoutStyle}>Logout</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Logout Confirmation"
                className="logout-modal"
                overlayClassName="overlay"
            >
                <h2>Confirm Logout</h2>
                <p>Are you sure you want to log out?</p>
                <div className="modal-buttons">
                    <button onClick={confirmLogout} className="confirm-button">Yes</button>
                    <button onClick={closeModal} className="cancel-button">No</button>
                </div>
            </Modal>
        </div>
    );
};

export default Logout;
