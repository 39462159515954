import axios from 'axios';
import { clear } from 'console';
import React, { useCallback, useEffect, useState } from 'react'
import DayDropdown from '../shapes/DayDropdown';
import DatePicker from '../shapes/DatePicker';
import Dropdown from '../shapes/Dropdown';
import '../../css/itinerary.css';
import { ListGroup } from 'react-bootstrap';
import { getCurrentDate } from '../../utilities/helper';
import { debounce } from 'lodash';

interface Itinerary {
  id: number;
  experience: string;
  day: string;
  date: string;
  description: string;
  p_breakdown: string;
  number_of_units: number;
  rate_net_vat: number | null;
  vatable: string;
  guest_rate: number | null;
  markup: number;
  vendorCostNetVat: number | null;
  vendorCostVat: number | null;
  payVendor: number | null;
  guestCostNetVat: number | null;
  guestCostVat: number | null;
  markupValue: number | null;
  markupVat: number | null;
  guestBillingAmount: number | null;
  grossContributionMargin: number | null;
}

function Itinerary() {
  const token = localStorage.getItem('token');
  const defaultItinerary = {
    id: 0,
    experience: '',
    day: '',
    date: '',
    description: '',
    p_breakdown: '',
    number_of_units: 1,
    rate_net_vat: null,
    vatable: '',
    guest_rate: null,
    markup: 0,
    vendorCostNetVat: 0,
    vendorCostVat: 0,
    payVendor: 0,
    guestCostNetVat: 0,
    guestCostVat: 0,
    markupValue: 0,
    markupVat: 0,
    guestBillingAmount: 0,
    grossContributionMargin: 0,

  }
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [trip, setTrip] = useState<Itinerary>(defaultItinerary);


  const days = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'ADDITIONAL'];
  const pBreakdown = ['LUMP SUM', 'PER UNIT'];
  const vatables = ['VATABLE', 'NOT VATABLE', 'N/A'];
  const optionDayBgColorLight = 'bg-violet-300';
  const optionDayBgColorDark = 'bg-violet-400';
  const optionDayBgColor = [optionDayBgColorLight, optionDayBgColorDark];
  const fetchInitialData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/get-trip-fields`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const data = response.data;
      console.log('data', data);
      console.log('data', data.itineraries.length);

      // Update the cell data state
      setItineraries(data.itineraries);
      // if (data.itineraries.length > 0) {
      //   setItineraries(data.itineraries)
      //   console.log('data', '> 0');
      // };
      // if (itineraries.length === 0) {
      //   setItineraries([defaultItinerary]);
      // } else {
      //   setItineraries((prevItineraries) => {
      //     return [...prevItineraries, { ...defaultItinerary, id: prevItineraries[prevItineraries.length - 1].id + 1, description: '', number_of_units: 1, rate_net_vat: null }];
      //   })
      // }
      if (data.itineraries.length === 0) {
        setItineraries([defaultItinerary]);
      } else {
        setItineraries((prevItineraries) => {
          return [...prevItineraries, { ...defaultItinerary, id: prevItineraries[prevItineraries.length - 1].id + 1, description: '', number_of_units: 1, rate_net_vat: null }];
        })
      }
      // console.log('data.itineraries ength', data.itineraries.length);
    } catch (error) {
      console.error(`Error fetching cell data:`, error);
    }
  }

  const handleInput = async (e: React.FormEvent<HTMLTableCellElement>) => {
    const newContent = e.currentTarget.textContent || '';
    const currentRow = e.currentTarget.parentElement as HTMLTableRowElement;
    const rowId = parseInt(currentRow.getAttribute('data-id') || '0');
    const cellName = e.currentTarget.getAttribute('data-name') || '';

    updateCellData(rowId, cellName, newContent);
  };

  const vatRate = (vatable: string) => {
    if (vatable === 'VATABLE') {
      return 15;
    } else {
      return 0;
    }
  }

  const updateDataFromDropdown = async (rowId: number, cellName: string, newValue: string) => {

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`,
      {
        tripId: rowId,
        fieldName: cellName,
        value: newValue,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Cell data updated successfully!", response.data);
    // set id in itineraries state to the new id
    if (itineraries && response.data) {
      setItineraries((prevItineraries) =>
        prevItineraries.map((itinerary) =>
          itinerary.id === rowId
            ? { ...itinerary, id: response.data.experienceId }
            : itinerary
        )
      );
    }
    const tableRow = document.querySelector(`tr[data-id="${rowId}"]`);
    if (tableRow) {
      // change the data-id attribute to the new rowId
      tableRow.setAttribute('data-id', response.data.experienceId);
    }

    // Update `trip` and `itineraries` state with the new value and recalculate `vendorCostNetVat` if necessary
    setTrip((prevTrip) => {
      const updatedTrip = { ...prevTrip, [cellName]: newValue };

      // Check if the updated field affects `vendorCostNetVat`
      if (['rate_net_vat', 'p_breakdown', 'number_of_units'].includes(cellName)) {
        updatedTrip.vendorCostNetVat = calculateVendorCostNetVat(
          Number(updatedTrip.rate_net_vat),
          updatedTrip.p_breakdown,
          Number(updatedTrip.number_of_units)
        );
      }

      return updatedTrip;
    });
  }


  const updateCellData = async (rowId: number, cellName: string, newValue: string) => {
    try {
      console.log('updateCellData', rowId, cellName, newValue);
      setItineraries((prevItineraries) =>
        prevItineraries.map((itinerary) => {
          if (itinerary.id === rowId) {
            // Create a copy of the itinerary and update the specified cell
            let updatedItinerary = { ...itinerary, [cellName]: newValue };

            // Check if `vendorCostNetVat` needs to be recalculated
            if (cellName === 'rate_net_vat' || cellName === 'p_breakdown' || cellName === 'number_of_units') {
              if (newValue === 'LUMP SUM') {
                updatedItinerary.number_of_units = 1;
              }
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(
                cellName === 'rate_net_vat' ? Number(newValue) : itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0,
                cellName === 'p_breakdown' ? newValue : itinerary.p_breakdown,
                cellName === 'number_of_units' ? Number(newValue) : itinerary.number_of_units
              );
            } else {
              updatedItinerary.vendorCostNetVat = itinerary.vendorCostNetVat;
            }

            return updatedItinerary;
          } else {
            // If this is not the matching row, return the original itinerary unchanged
            return itinerary;
          }
        })
      );

      // const response = await axios.post(
      //   `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`,
      //   {
      //     tripId: rowId,
      //     fieldName: cellName,
      //     value: newValue,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // console.log("Cell data updated successfully!", response.data);
      // // set id in itineraries state to the new id
      // if (itineraries && response.data) {
      //   setItineraries((prevItineraries) =>
      //     prevItineraries.map((itinerary) =>
      //       itinerary.id === rowId
      //         ? { ...itinerary, id: response.data.experienceId }
      //         : itinerary
      //     )
      //   );
      // }
      // const tableRow = document.querySelector(`tr[data-id="${rowId}"]`);
      // if (tableRow) {
      //   // change the data-id attribute to the new rowId
      //   tableRow.setAttribute('data-id', response.data.experienceId);
      // }

      // // Update `trip` and `itineraries` state with the new value and recalculate `vendorCostNetVat` if necessary
      // setTrip((prevTrip) => {
      //   const updatedTrip = { ...prevTrip, [cellName]: newValue };

      //   // Check if the updated field affects `vendorCostNetVat`
      //   if (['rate_net_vat', 'p_breakdown', 'number_of_units'].includes(cellName)) {
      //     updatedTrip.vendorCostNetVat = calculateVendorCostNetVat(
      //       Number(updatedTrip.rate_net_vat),
      //       updatedTrip.p_breakdown,
      //       Number(updatedTrip.number_of_units)
      //     );
      //   }

      //   return updatedTrip;
      // });

    } catch (error) {
      console.error("Error updating cell data:", error);
    }
  };

  const debouncedUpdateCellData = useCallback(
    debounce(async (rowId: number, cellName: string, newValue: string) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-trip-fields`,
          { tripId: rowId, fieldName: cellName, value: newValue },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (itineraries && response.data) {
          setItineraries((prevItineraries) =>
            prevItineraries.map((itinerary) =>
              itinerary.id === rowId
                ? { ...itinerary, id: response.data.experienceId }
                : itinerary
            )
          );
        }
        console.log("Cell data updated successfully!", response.data);
      } catch (error) {
        console.error("Error updating cell data:", error);
      }
    }, 500),
    [] // Empty dependency array to ensure it's defined only once
  );


  const handleInputChange = useCallback(
    (
      rowId: number,
      cellName: string,
      value: string,
      title: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
      // Update the itineraries state
      setItineraries((prevItineraries) => {
        const vatRate = (vatable: string) => {
          if (vatable === 'VATABLE') {
            return 15;
          } else {
            return 0;
          }
        }
        const updatedItineraries = prevItineraries.map((itinerary) => {
          if (itinerary.id === rowId) {
            let updatedItinerary = { ...itinerary, [cellName]: value };

            if (cellName === 'rate_net_vat' || cellName === 'p_breakdown' || cellName === 'number_of_units') {
              updatedItinerary.vendorCostNetVat = calculateVendorCostNetVat(
                cellName === 'rate_net_vat' ? Number(value) : itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0,
                cellName === 'p_breakdown' ? value : itinerary.p_breakdown,
                cellName === 'number_of_units' ? Number(value) : itinerary.number_of_units
              );
              console.clear();
              console.log(calculateVendorCostVat(updatedItinerary.vendorCostNetVat, vatRate(itinerary.vatable)));
              updatedItinerary.vendorCostVat = calculateVendorCostVat(
                updatedItinerary.vendorCostNetVat,
                vatRate(itinerary.vatable)
              );
              updatedItinerary.payVendor = updatedItinerary.vendorCostNetVat + updatedItinerary.vendorCostVat;
            } else if (cellName === 'guest_rate' || cellName === 'number_of_units' || cellName === 'markup') {
              updatedItinerary.guestCostNetVat = (updatedItinerary.guest_rate !== null ? updatedItinerary.guest_rate : 0) * itinerary.number_of_units;
              updatedItinerary.guestCostVat = updatedItinerary.guestCostNetVat * 0.15;
              updatedItinerary.markupValue = updatedItinerary.guestCostNetVat
                ? updatedItinerary.guestCostNetVat * (updatedItinerary.markup / 100)
                : 0;
              updatedItinerary.markupVat = updatedItinerary.markupValue ? updatedItinerary.markupValue * 0.15 : 0;
              updatedItinerary.guestBillingAmount =
                updatedItinerary.guestCostNetVat +
                updatedItinerary.guestCostVat +
                updatedItinerary.markupValue +
                updatedItinerary.markupVat;
            }
            return updatedItinerary;
          }
          return itinerary;
        });

        // Check if the current row is the last one; if so, add a new row
        if (prevItineraries[prevItineraries.length - 1].id === rowId) {
          const newRow = { ...defaultItinerary, id: rowId + 1, description: '', number_of_units: 1, rate_net_vat: null };
          return [...updatedItineraries, newRow];
        }

        return updatedItineraries;
      });

      // Additional updates
      const itinerary = itineraries.find((itinerary) => itinerary.id === rowId);
      if (itinerary) {
        displayTableCells(title, itinerary);
      }

      // Update trip state if the edited row matches trip.id
      if (trip.id === rowId) {
        setTrip((prevTrip) => ({
          ...prevTrip,
          [cellName]: value,
          vendorCostNetVat: calculateVendorCostNetVat(
            cellName === 'rate_net_vat' ? Number(value) : prevTrip.rate_net_vat !== null ? prevTrip.rate_net_vat : 0,
            cellName === 'p_breakdown' ? value : prevTrip.p_breakdown,
            cellName === 'number_of_units' ? Number(value) : prevTrip.number_of_units
          ),
        }));
      }

      // Update cell data on the server
      debouncedUpdateCellData(rowId, cellName, value);
    },
    [debouncedUpdateCellData] // Only update if debounced function changes
  );


  // onBlur handler for formatting
  const handleBlur = (rowId: number) => {
    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) => {
        if (itinerary.id === rowId) {
          return {
            ...itinerary,
            rate_net_vat: itinerary.rate_net_vat !== null ? parseFloat(formatNumber(itinerary.rate_net_vat)) : null,
            vendorCostNetVat: itinerary.vendorCostNetVat !== null ? parseFloat(formatNumber(itinerary.vendorCostNetVat)) : null,
            vendorCostVat: itinerary.vendorCostVat !== null ? parseFloat(formatNumber(itinerary.vendorCostVat)) : null,
            guest_rate: itinerary.guest_rate !== null ? parseFloat(formatNumber(itinerary.guest_rate)) : null,
          };
        }
        return itinerary;
      })
    );
  };

  const calculateVendorCostNetVat = (rate: number, pBreakdown: string, units: number) => {
    return pBreakdown === 'PER UNIT' ? rate * units : rate;
  };

  const calculateVendorCostVat = (vendorCostNetVat: number, vatRate: number) => {
    return vendorCostNetVat * (vatRate / 100);
  }

  const displayTableCells = (title: string, itinerary: Itinerary) => {
    let result: any = null;
    let vatRate: number | null = null;
    let vendorCostNetVat: number | null = null;
    let vendorCostVat: number | null = null;
    let guestCostNetVat: number | null = null;
    let guestCostVat: number | null = null;
    let markupValue: number | null = null;
    if (title === 'Experience') {
      result = itinerary.experience;
    } else if (title === 'Day') {
      result = itinerary.day;
    } else if (title === 'Date') {
      result = itinerary.date;
    } else if (title === 'Description') {
      result = itinerary.description;
    } else if (title === 'P.Breakdown') {
      result = itinerary.p_breakdown;
    } else if (title === 'number_of_units') {
      result = itinerary.number_of_units;
    } else if (title === 'RATE - Net Vat') {
      result = vatRate = itinerary.rate_net_vat;
    } else if (title === 'VATABLE') {
      result = itinerary.vatable;
    } else if (title === 'Vendor Cost - Net Vat') {
      result = vendorCostNetVat = calculateVendorCostNetVat(itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0, itinerary.p_breakdown, itinerary.number_of_units);
      // console.log(result);
    } else if (title === 'Vendor Cost Vat') {
      // result = vendorCostVat = vendorCostNetVat && vatRate ? vendorCostNetVat * (vatRate / 100) : 0;
      const vatRate = (vatable: string) => {
        if (vatable === 'VATABLE') {
          return 15;
        } else {
          return 0;
        }
      }
      result = vendorCostVat = calculateVendorCostVat(calculateVendorCostNetVat(itinerary.rate_net_vat !== null ? itinerary.rate_net_vat : 0, itinerary.p_breakdown, itinerary.number_of_units), vatRate(itinerary.vatable));
      // setItineraries((prevItineraries) =>
      //   prevItineraries.map((itinerary) =>
      //     itinerary.id === itinerary.id
      //       ? { ...itinerary, vendorCostVat: vendorCostVat }
      //       : itinerary
      //   )
      // );
    } else if (title === 'PAY VENDOR') {
      // console.log('PAY VENDOR');
      // console.log(itinerary.payVendor ? itinerary.payVendor : 0);
      result = itinerary.payVendor ? itinerary.payVendor : 0;
    } else if (title === 'GUEST RATE') {
      result = itinerary.guest_rate;
    } else if (title === 'Guest Cost - NET VAT') {
      result = guestCostNetVat = (itinerary.guest_rate !== null ? itinerary.guest_rate : 0) * itinerary.number_of_units;
    } else if (title === 'Guest Cost VAT') {
      result = guestCostVat = itinerary.guestCostNetVat ? itinerary.guestCostNetVat * 0.15 : 0;
    } else if (title === 'Mark Up') {
      result = itinerary.markup;
    } else if (title === 'Mark Up VALUE') {
      result = markupValue = itinerary.guestCostNetVat ? itinerary.guestCostNetVat * (itinerary.markup / 100) : 0;
    } else if (title === 'Mark Up VAT') {
      result = itinerary.markupValue != null ? itinerary.markupValue * .15 : null;
    } else if (title === 'GUEST BILLING AMOUNT') {
      result = itinerary.guestCostNetVat != null && itinerary.guestCostVat != null && itinerary.markupValue != null && itinerary.markupVat != null ? itinerary.guestCostNetVat + itinerary.guestCostVat + itinerary.markupValue + itinerary.markupVat : null;
    } else if (title === 'GROSS CONTRIBUTION MARGIN') {
      result = itinerary.guestBillingAmount && itinerary.payVendor ? itinerary.guestBillingAmount - itinerary.payVendor : 0;
    } else if (title === 'NET CONTRIBUTION MARGIN') {
      // console.log("guestBillingAmount: " + itinerary.guestBillingAmount + " payVendor: " + itinerary.payVendor + " guestCostVat: " + itinerary.guestCostVat + " markupVat: " + itinerary.markupVat + " vendorCostVat: " + itinerary.vendorCostVat);
      result = itinerary.guestBillingAmount != null && itinerary.payVendor != null && itinerary.guestCostVat != null && itinerary.markupVat != null && itinerary.vendorCostVat != null
        ?
        itinerary.guestBillingAmount - itinerary.payVendor - itinerary.guestCostVat - itinerary.markupVat + itinerary.vendorCostVat : 'cat';
    }
    // add comma seperator and 2 decimal places
    if (result !== null && result !== undefined) {
      if (title !== 'number_of_units' && title !== 'Mark Up') {
        result = result.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      }
    }
    return result;
  }

  const formatNumber = (value: number | null) => {
    if (value === null) return '';
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    console.log('itineraries', itineraries);
  }, [itineraries]);

  useEffect(() => {
    // console.log('Trip State: ', trip);
    // console.log('Trip id: ', trip.id);
    // console.log('setItineraries and add vendorCostNetVat: ', calculateVendorCostNetVat(trip.rate_net_vat !== null ? trip.rate_net_vat : 0, trip.p_breakdown, trip.number_of_units));
    const newVendorCostNetVat = calculateVendorCostNetVat(trip.rate_net_vat !== null ? trip.rate_net_vat : 0, trip.p_breakdown, trip.number_of_units);

    setItineraries((prevItineraries) =>
      prevItineraries.map((itinerary) =>
        itinerary.id === trip.id
          ? { ...itinerary, vendorCostNetVat: newVendorCostNetVat }
          : itinerary
      )
    );
  }, [trip]);

  return (
    <div className="overflow-auto rounded-lg shadow-lg h-[100vh]">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-100 border-b-2 border-gray-200 sticky top-0 z-20">
          <tr>
            {[
              'Experience', 'Day', 'Date', 'Description', 'P.Breakdown', 'Units', 'RATE - Net Vat',
              'VATABLE', 'VAT RATE', 'Vendor Cost - Net Vat', 'Vendor Cost VAT', 'PAY VENDOR', 'GUEST RATE',
              'Guest Cost - NET VAT', 'Guest Cost VAT', 'Mark Up %', 'Mark Up VALUE', 'Mark Up VAT',
              'GUEST BILLING AMOUNT', 'GROSS CONTRIBUTION MARGIN', 'NET CONTRIBUTION MARGIN', 'Vendor', 'Invoice', 'POP',
              'Payment Status', 'PAID/COMP', 'Vendor 1', 'Beneficiary Name', 'Iban', 'Bank Name',
              'Payment Method'
            ].map((header, index) => (
              <th
                key={index}
                className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase ${index == 0 ? 'sticky left-0 z-10 bg-gray-100' : ''}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            itineraries && Array.isArray(itineraries) && itineraries.map((itinerary, index) => (
              <tr key={index} className="even:bg-gray-50 hover:bg-blue-50 transition-colors" data-id={itinerary.id} onClick={() => setTrip(itinerary)}>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className='custom-cell sticky left-0 z-10 border-r-2 border-r-black bg-white' data-name="experience">
                  <input
                    type="text"
                    value={displayTableCells('Experience', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'experience', e.target.value, 'Experience', e)}
                    className="border-none outline-none bg-transparent w-full"
                  />
                </td>
                <td className="custom-cell i-border-r" data-name="day">
                  <DayDropdown
                    days={days}
                    selectedDay={displayTableCells('Day', itinerary)}
                    defaultSelectedDay="1"
                    onChange={(selectedDay) => updateDataFromDropdown(itinerary.id, "day", selectedDay)}
                  />
                </td>
                <td className="custom-cell i-border-r" data-name="date">
                  <DatePicker
                    initialDate={displayTableCells('Date', itinerary)}
                    defaultSelectedDate={getCurrentDate()}
                    onDateChange={(formattedDate) => updateDataFromDropdown(itinerary.id, "date", formattedDate)}
                  />
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="description">
                  <input
                    type="text"
                    value={displayTableCells('Description', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, 'Description', e)}
                    className="border-none outline-none bg-transparent w-full"
                  />
                </td>
                <td className="custom-cell i-border-r" data-name="breakdown">
                  <Dropdown
                    items={pBreakdown}
                    selectedItem={displayTableCells('P.Breakdown', itinerary)}
                    defaultSelectedItem='LUMP SUM'
                    onChange={(selectedItem) => updateDataFromDropdown(itinerary.id, "p_breakdown", selectedItem)}
                    otherClasses='min-w-[70px]'
                  />
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="number_of_units">
                  <input
                    type="text"
                    value={displayTableCells('number_of_units', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'number_of_units', e.target.value, 'number_of_units', e)}
                    className="border-none outline-none bg-transparent w-full text-right"
                  />
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="rate_net_vat">
                  <input
                    type="text"
                    value={displayTableCells('RATE - Net Vat', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'rate_net_vat', e.target.value, 'RATE - Net Vat', e)}
                    onBlur={() => handleBlur(itinerary.id)}
                    className="border-none outline-none bg-transparent w-full text-right"
                  />
                </td>
                <td className="custom-cell i-border-r" data-name="vatable">
                  <Dropdown
                    items={vatables}
                    selectedItem={displayTableCells('VATABLE', itinerary)}
                    defaultSelectedItem='NOT VATABLE'
                    onChange={(selectedItem) => updateDataFromDropdown(itinerary.id, "vatable", selectedItem)}
                    otherClasses='min-w-[84px]'
                  />
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r vat-rate text-right">
                  {vatRate(itinerary.vatable)}%
                </td>
                <td className="custom-cell i-border-r vendor-cost-net-vat text-right">
                  {displayTableCells('Vendor Cost - Net Vat', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r vendor-cost-vat text-right">
                  {displayTableCells('Vendor Cost Vat', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r pay-vendor text-right">
                  {displayTableCells('PAY VENDOR', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r guest-rate">
                  <input
                    type="text"
                    value={displayTableCells('GUEST RATE', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'guest_rate', e.target.value, 'RATE - Net Vat', e)}
                    onBlur={() => handleBlur(itinerary.id)}
                    className="border-none outline-none bg-transparent w-full text-right"
                  />
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r guest_cost_net_vat text-right">
                  {displayTableCells('Guest Cost - NET VAT', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r guest_cost_vat text-right">
                  {displayTableCells('Guest Cost VAT', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r markup text-right" data-name="markup">
                  <input
                    type="text"
                    value={displayTableCells('Mark Up', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'markup', e.target.value, 'Mark Up', e)}
                    className="border-none outline-none bg-transparent w-32 text-right"
                  />
                  <span className='pr-1'>%</span>
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r markup-value text-right">
                  {/* <input
                    type="text"
                    value={displayTableCells('Mark Up VALUE', itinerary)}
                    onChange={(e) => handleInputChange(itinerary.id, 'markupValue', e.target.value, 'Mark Up VALUE')}
                    className="border-none outline-none bg-transparent w-full text-right"
                  /> */}
                  {displayTableCells('Mark Up VALUE', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r markup-vat text-right">
                  {displayTableCells('Mark Up VAT', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r guest-billing-amount text-right">
                  {displayTableCells('GUEST BILLING AMOUNT', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r guest-contribution-margin text-right">
                  {displayTableCells('GROSS CONTRIBUTION MARGIN', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r net-contribution-margin text-right">
                  {displayTableCells('NET CONTRIBUTION MARGIN', itinerary)}
                </td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
                <td suppressContentEditableWarning onInput={(e) => handleInput(e)} className="custom-cell i-border-r" data-name="" contentEditable></td>
              </tr>
            ))

          }

        </tbody>
      </table>
    </div>
  )
}

export default Itinerary