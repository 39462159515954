import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './../../reducers/reducers';

const LeanConnect: React.FC = () => {
    const user = useSelector((state: RootState) => state.userState);
    const link = async () => {
        try {
            const token = localStorage.getItem('token');
            
            // Get the customer access token from backend
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/customer-token`, {
            // const response = await axios.get(`${process.env.REACT_APP_API_URL}/lean/customer-token`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log(response.data)
            const customerToken = response.data.customer_token;
            const leanCustomerId = response.data.lean_customer_id;
            const leanAppToken = response.data.lean_app_token;

            // console.log(customerToken);
            // console.log(leanCustomerId);

            if (window.Lean) {
                
                // Calculate access_from (6 months back) and access_to (1 day in the future)
                const now = new Date();

                // Access From: 6 months back
                const accessFromDate = new Date();
                accessFromDate.setMonth(now.getMonth() - 6);

                // Access To: 1 day in the future
                const accessToDate = new Date();
                accessToDate.setDate(now.getDate() + 1);

                // Format dates to ISO strings without milliseconds and timezone
                const accessFromISOString = accessFromDate.toISOString().split('.')[0] + 'Z';
                const accessToISOString = accessToDate.toISOString().split('.')[0] + 'Z';
                
                window.Lean.connect({
                    app_token: leanAppToken,
                    permissions: ["identity", "accounts", "balance", "transactions"],
                    customer_id: leanCustomerId,
                    sandbox: false,
                    access_token: customerToken,
                    show_consent_explanation: true,
                    fail_redirect_url: 'https://sejel.io/lean/failure',
                    success_redirect_url: 'https://sejel.io/lean/success',
                    account_type: 'business',
                    access_from: accessFromISOString,
                    access_to: accessToISOString,
                  });
                  
            } else {
                console.error('Lean SDK is not loaded.');
            }
        } catch (error) {
            console.error('Error initializing Lean SDK:', error);
        }
    };

    return (
        <div>
            <button onClick={link}>Connect Bank Account</button>
        </div>
    );
};

export default LeanConnect;
