import React, { useEffect, useState } from 'react'
import LandingMenu from './landing/LandingMenu'
import WorkspaceInfo from './../assets/WorkspaceInfo.svg'
import CreditCard from './../assets/CreditCard.svg'
import Users from './../assets/Users.svg'
import DeleteUser from './../assets/DeleteUser.webp'
import MoreOptions from './../assets/MoreOptions.png'
import RefreshUser from './../assets/RefreshUser.png'
import CancelRequest from './../assets/CancelRequest.png'
import '../css/collaborator.css'
import axios from 'axios'
import Alert from './shapes/Alert'
import { AuthData } from './../types/auth'
import withAuthorization from './auth/withAuthorization'
import Logout from './auth/Logout'
import { url } from 'inspector'
import ModalComponent from './shapes/ModalComponent'
import { User } from '../types/User'
import UserCircle from './auth/UserCircle'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

interface Role {
  id: number;
  name: string;
  role_specification: string;
}

interface Props {
  role: any;
}

const user = localStorage.getItem('user');
const userName = user ? JSON.parse(user).name : '';
const UserShortName = userName ? userName.substring(0, 2).toUpperCase() : '';


const Collaborator: React.FC<Props> = ({ role }) => {
  const { uniCode } = useParams();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [roles, setRoles] = useState<Role[]>();
  const [owner, setOwner] = useState<User>();
  const [members, setMembers] = useState<User[]>();
  const [roleId, setRoleId] = useState('');
  const [collaboratorEmail, setCollaboratorEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>({
    id: -1,
    name: '',
    snippet: '',
    description: '',
    uniCode: '',
    image: '',
  });
  const pages = {
    'Workspace Info': [`${selectedWorkspace && selectedWorkspace.id > 0 ? '/workspace-info/' + uniCode : '/workspace-info/'}`, WorkspaceInfo],
    'Collaborator': ['/collaborator/' + uniCode, Users],
    'Plan and Billing': ['', CreditCard],
  }

  const inviteCollaborator = async (token: string | null, data: FormData, ownerEmail: string, emailInput: HTMLInputElement) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/invite-collaborator`;
      setLoading(true)
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.clear();
      console.log(response);
      setMembers(response.data.members);
      setShowAlert(true)
      setAlertBody('Collaborator has been invited successfully!');
      setAlertStatus('success');
    } catch (error: any) {
      setRoleId('0')
      if (error.response && error.response.status === 409) {
        console.error('Conflict error: collaborator already exists!');
        setAlertBody('Collaborator already exists.');
      }
      // console.error(error);
      setShowAlert(true)
      setAlertStatus('error');
      const roleIdSelect = document.querySelector('select[name="role_id"]') as HTMLSelectElement;
      if (roleIdSelect) {
        roleIdSelect.selectedIndex = 0;
        roleIdSelect.value = '0';
      }

    } finally {
      setTimeout(() => {
        setShowAlert(false)
      }, 5000);
      setLoading(false);
      emailInput.value = "";
      const inviteButton = document.querySelector('.invite-submit') as HTMLButtonElement;
      inviteButton.classList.add('inactive');
      inviteButton.classList.remove('clickable');
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData();
    const emailInput = (document.querySelector('input[type="email"]') as HTMLInputElement);
    const roleSelect = (document.querySelector('select[name="role_id"]') as HTMLSelectElement);
    data.append('uniCode', uniCode ? uniCode : '');
    data.append('email', emailInput.value)
    data.append('role', roleSelect.value);
    // data.append('role_specification', selectedRole?.role_specification || roles?.[0].role_specification || '[Rle Specific]');
    if (uniCode) {
      data.append('workspaceUniCode', uniCode);
    } else {
      console.error('workspaceId is undefined');
    }
    const auth: AuthData = JSON.parse(localStorage.getItem('user') as string);
    if (auth) {
    }
    const ownerEmail = auth['email'];
    data.append('ownerEmail', ownerEmail)
    inviteCollaborator(token, data, ownerEmail, emailInput);
  }
  useEffect(() => {
    console.log("roleId: " + roleId);
  }, [roleId]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    let email = '';
    if (e.target.tagName === 'SELECT') {
      email = (document.querySelector('input[type="email"]') as HTMLInputElement).value;
      setRoleId(e.target.value)
    } else {
      email = e.target.value;
    }
    const inviteButton = document.querySelector('.invite-submit') as HTMLButtonElement;
    const role = document.getElementById('role_id') as HTMLSelectElement;

    const isValidEmail = (email: string) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    if (isValidEmail(email) && role.value !== '0') {
      inviteButton.classList.remove('inactive');
    } else {
      inviteButton.classList.add('inactive');
    }
  }

  const handleResendInvitation = async (e: React.MouseEvent<HTMLImageElement>) => {
    const loadingTag = e.currentTarget.nextElementSibling as HTMLElement;
    try {
      // get the next p tag
      loadingTag.style.display = 'inline-block';
      setResendLoading(true)

      const data = new FormData();
      const collaboratorEmail = e.currentTarget.closest('.user-row')?.querySelector('.user-email') as HTMLElement;
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/resend-invitation`;
      data.append('email', collaboratorEmail.innerHTML);

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setShowAlert(true)
      setAlertBody('Collaborator has been re-invited successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error(error);
      setShowAlert(true)
      setAlertBody('Collaborator has not been re-invited.');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false)
        loadingTag.style.display = 'none';
      }, 5000);
      setResendLoading(false);
    }
  }

  const handleCancelInvitation = async (e: React.MouseEvent<HTMLElement>, action: string, closeModal: () => void) => {
    e.currentTarget.classList.add('inactive');
    try {
      setResendLoading(true)

      const data = new FormData();
      // const collaboratorEmail = e.currentTarget.closest('.user-row')?.querySelector('.user-email') as HTMLElement;

      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/cancel-invitation`;

      data.append('email', collaboratorEmail);
      data.append('workspaceUniCode', uniCode ? uniCode : '');

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.clear();
      console.log(response);
      setShowAlert(true)
      setMembers(response.data.members);
      if (action === 'delete') {
        setAlertBody('Collaborator has been deleted successfully!');
      } else if (action === 'cancel') {
        setAlertBody('Invitation has been cancelled successfully!');
      }
      setAlertStatus('success');
      closeModal();
    } catch (error) {
      console.error(error);
      setShowAlert(true)
      if (action === 'delete') {
        setAlertBody('Collaborator has not been deleted.');
      } else if (action === 'cancel') {
        setAlertBody('Invitation has bot been cancelled.');
      }
      setAlertStatus('error');
      if (e.currentTarget)
        e.currentTarget.classList.remove('inactive');
    } finally {
      // e.currentTarget.classList.remove('inactive');
      setTimeout(() => {
        setShowAlert(false)
      }, 5000);
      setResendLoading(false);
    }
  }

  const initialRequest = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/collaborator`;
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : {};
    const data = {
      user: user,
      workspaceUniCode: uniCode
    }
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setRoles(response.data.roles);
    setOwner(response.data.owner);
    setMembers(response.data.members);
    setSelectedWorkspace(response.data.selectedWorkspace);
    // console.log('response: ', response);
  }
  const handleShowAccountPopup = () => {
    const accountPopup = document.getElementById('account-popup') as HTMLElement;
    if (accountPopup) {
      if (accountPopup.classList.contains('overnight')) {
        accountPopup.classList.remove('overnight');
      } else {
        accountPopup.classList.add('overnight');
      }
    }
  }

  const getCollaboratorEmail = (e: React.MouseEvent<HTMLElement>) => {
    const collaboratorEmailText = e.currentTarget.closest('.user-row')?.querySelector('.user-email') as HTMLElement;
    setCollaboratorEmail(collaboratorEmailText.innerHTML);
  }

  useEffect(() => {
    console.log(uniCode);
    if (uniCode) {
      initialRequest();
    }
  }, [uniCode]);
  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <LandingMenu pages={pages} hidePopup={true} hidePanelContents={false} pageProp="collaborator" selectedWorkspace={selectedWorkspace} />

      <div id="collaborator-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Collaborators</h3>
            <p>Add collaborators to this workspace</p>
          </div>
          <UserCircle />
        </div>

        <div id="invite-collaborator" className='small-title'>
          <h3>Invite Collaborators</h3>
        </div>
        <div id="invite-collaborator-form" className='bg-white rounded-radius'>
          <form onSubmit={handleSubmit}>
            <div className="flex-container">
              <div className="flex-2-2 w-50">
                <input type="email" id='collaborator-email' placeholder="Email Address" onChange={handleEmailChange} />
              </div>
              <div className="flex-2-2 w-50">
                <div className="flex-container flex-start">
                  <div className="user-role user-role-drop-down upper-case rounded-radius fit-content role-shape">
                    <select name="role_id" id="role_id" onChange={handleEmailChange} value={roleId}>
                      <option value="0">FUNCTION</option>
                      {
                        roles?.map((role) => {
                          return <option key={role.id} value={role.id}>{role.name.toUpperCase()}</option>
                        })
                      }
                    </select>
                  </div>
                  <button id='invite-submit' className="edit-viewing invite-submit rounded-radius inactive clickable">Invite</button>
                  {
                    loading === true ? <p className="bold-text" style={
                      {
                        'color': '#94ddf3',
                        'display': 'inline-block',
                        'marginLeft': '1rem',
                        'fontSize': '2rem',
                      }}>
                      Loading...
                    </p> : ""
                  }
                </div>
              </div>
            </div>
          </form>
        </div>

        <div id="collaborator-owner">
          <div className="small-title">
            <h3>Workspace Owner</h3>
          </div>
          <div className="white-box bg-white rounded-radius flex-container flex-start w-100">
            <div className="flex-2-2 w-50">
              <div className="flex-container flex-start">
                <div className="user-circle small-circle">
                  {
                    owner?.profile_image
                      ? <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + owner.profile_image} className="member-image image-circle" />
                      : ''
                  }
                </div>
                <div className='user-info'>
                  <p className='user-name bold-text'>{owner ? owner.name : ''}</p>
                  <p className='user-email bold-text'>{owner ? owner.email : ''}</p>
                </div>
              </div>
            </div>
            <div className="flex-2-2 w-50">
              <div className="user-role upper-case rounded-radius role-shape">owner</div>
            </div>
          </div>
        </div>

        <div id="workspace-collaborator" className={`${members ? '' : 'overnight'}`}>
          <div className="small-title">
            <h3>Workspace Members</h3>
          </div>
        </div>
        {members && members.length > 0
          ?
          <div className={`white-box bg-white rounded-radius w-100 ${members ? '' : 'overnight'}`}>
            {
              members?.map((member) => {
                return (
                  <div className="user-row w-100 flex-container flex-start" key={member.id}>
                    <div className="flex-2-2 w-50">
                      <div className="flex-container flex-start">
                        <div className="user-circle small-circle">
                          {
                            member.profile_image
                              ? <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + member.profile_image} className="member-image image-circle" />
                              : ''
                          }
                        </div>
                        <div className='user-info'>
                          <p className='user-name bold-text'>{member.name === "NOT AUTHENTICATED" ? "---" : member.name}</p>
                          <p className='user-email bold-text'>{member.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex-2-2 w-50">
                      <div className="flex-container flex-start">
                        <div className="user-role upper-case rounded-radius role-shape">
                          {roles ? (roles.find(role => role.id === member.role_id)?.name || '') : ''}
                        </div>
                        <div className={`edit-viewing upper-case rounded-radius m-l-1 ${member.name === "NOT AUTHENTICATED" ? '' : 'bg-grassy'}`}>{member.name === "NOT AUTHENTICATED" ? "Invited" : "Accepted"}</div>
                        <div className="edit-viewing upper-case rounded-radius m-l-1">Edit Viewing Authority</div>
                        <div className="m-l-1">
                          {
                            member.name === "NOT AUTHENTICATED"
                              // ? <img src={CancelRequest} alt="delete-user" className='role_image delete-user clickable pointer' onClick={handleCancelInvitation} />
                              ?
                              <div onClick={getCollaboratorEmail}>
                                <ModalComponent
                                  logoutStyle='from-menu'
                                  content={CancelRequest}
                                  classProp={'role_image delete-user clickable pointer'}
                                  message={'Are you sure you want to cancel the invitation?'}
                                  action={(e: React.MouseEvent<HTMLElement>, action: string, closeModal) => handleCancelInvitation(e, 'cancel', closeModal)}
                                  actionName='cancel'
                                  label="CANCEL INVITATION"
                                />
                              </div>
                              :
                              // <img src={DeleteUser} alt="delete-user" className='role_image delete-user clickable pointer' onClick={handleCancelInvitation} />
                              <div onClick={getCollaboratorEmail}>
                                <ModalComponent
                                  logoutStyle='from-menu'
                                  content={DeleteUser}
                                  classProp={'role_image delete-user clickable pointer'}
                                  message={'Are you sure you want to delete the user?'}
                                  action={(e, action, closeModal) => handleCancelInvitation(e, 'delete', closeModal)}
                                  actionName='delete'
                                  label="DELETE THIS USER"
                                />
                              </div>
                          }
                        </div>
                        <div className="m-l-1">
                          {
                            member.name === "NOT AUTHENTICATED"
                              ?
                              <>
                                <img src={RefreshUser} alt="delete-user" className={`role_image delete-user pointer clickable`} onClick={handleResendInvitation} />
                                <p className="bold-text" style={
                                  {
                                    'color': '#94ddf3',
                                    'display': 'none',
                                    'marginLeft': '1rem',
                                    'fontSize': '1.2rem',
                                  }}>
                                  Loading...
                                </p>
                              </>
                              :
                              <div className="image-container">
                                <div className="image-container">
                                  <img src={MoreOptions} alt="delete-user" className='role_image refresh-email' />
                                  <span className="hover-label">RESEND INVITATION</span>
                                </div>
                                <div className="hover-label">EDIT USER PROFIT</div>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          : ''}

      </div>
    </div>
  )
}

export default withAuthorization(Collaborator, ['Workspace Owner', 'Administrator']);