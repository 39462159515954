import React from 'react'
import Dashboard from '../../assets/Dashboard.svg';
import BankBuilding from '../../assets/BankBuilding.svg';
import ComboChart from '../../assets/ComboChart.svg';
import Menu from '../../assets/Menu.svg';
import Airtable from '../../assets/Airtable.svg';
import InvoiceNav from '../../assets/InvoiceNav.png';
import AI from '../../assets/AI.svg';
import { Link, useParams } from 'react-router-dom';
import Logout from '../auth/Logout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Workspace } from '../../types/Workspace';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface props {
  selectedWorkspace: Workspace;
}

const Navbar = ({ selectedWorkspace }) => {
  const { id } = useParams();
  // console.clear();
  console.log(selectedWorkspace)

  const handleShowMenu = () => {
    const menu = document.getElementById('menu')
    const logout = document.getElementById('logout')

    if (menu?.style.left === '0px' && logout?.style.left === '0px') {
      menu.style.left = '-16.7%';
      logout.style.left = '-16.7%';
    } else {
      if (menu) menu.style.left = '0px';
      if (logout) logout.style.left = '0px';
    }
  }

  // const handleShowInvoiceContainer = () => {
  //   const invoiceOptionContainer = document.querySelector('.invoice-option-container') as HTMLElement;

  //   if (invoiceOptionContainer?.style.display === 'block') {
  //     invoiceOptionContainer.style.display = 'none';
  //   } else {
  //     if (invoiceOptionContainer) invoiceOptionContainer.style.display = 'block';
  //   }
  // }

  // const handleShowInvoicePopup = () => {
  //   const invoicePopup = document.querySelector('#invoice-popup') as HTMLElement;

  //   if (invoicePopup?.style.display === 'block') {
  //     invoicePopup.style.display = 'none';
  //   } else {
  //     if (invoicePopup) invoicePopup.style.display = 'block';
  //   }
  // }

  return (
    <>
      <div id='navbar-side' className='vertical-flex-container'>
        <img src={Menu} alt="Menu" id='menu-icon' onClick={handleShowMenu} />
        <Link to={`/dashboard/${selectedWorkspace.uniCode}`}>
          <img src={Dashboard} alt="Dashboard" className='nav-item' />
        </Link>
        <Link to="/banks">
          <img src={BankBuilding} alt="BankBuilding" className='nav-item' />
        </Link>
        <Link to="/reports">
          <img src={ComboChart} alt="ComboChart" className='nav-item' />
        </Link>
        <Link to="/bases">
          <img src={Airtable} alt="Airtable" className='nav-item' />
        </Link>
        <Link to={`/invoices/${selectedWorkspace.uniCode}`}>
          <img src={InvoiceNav} alt="Airtable" className='nav-item clickable' />
        </Link>
        <Link to={`/clients/${selectedWorkspace.uniCode}`} className='nav-item users'>
          <FontAwesomeIcon icon={faUsers} size="2x" color="#00F" />
        </Link>
        {/* <Logout /> */}
        <div className="vertical-flex-container menu-width">
          <img src={AI} alt="AI" className='nav-item bottom-position' />
        </div>
      </div>
    </>
  )
}

export default Navbar;