import React from 'react'
import '../../css/base.css'
import { Link } from 'react-router-dom'
import SyncButton from '../Databases/SyncButton';
import LeanConnect from '../Lean/LeanConnect';
const Base = () => {
  return (
    <>
      <h1>Bases <SyncButton workspaceId={1} /></h1>
      <hr />
      <div className="container1">
        <div className="row">
          <div className="col-2">
            <Link to="/rev-db" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>REV DB</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-2">
            <Link to="/exp-db" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>EXP DB</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-2">
            <Link to="/assets-db" style={{ "textDecoration": "none" }}>
              <div className="square">
                <div className="square-content">
                  <h1>ASSETS DB</h1>
                  <p></p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <LeanConnect />
    </>
  )
}

export default Base