import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface AccountIdentifier {
  scheme_name: string;
  identification: string;
  name: string | null;
}

interface Account {
  id: number;
  bank_connection_id: number;
  account_id: string;
  status: string;
  status_update_date_time: string;
  currency: string;
  account_type: string;
  account_sub_type: string;
  nickname: string;
  opening_date: string;
  account: AccountIdentifier[];
  servicer: {
    scheme_name: string;
    identification: string;
  };
  description: string;
  maturity_date: string | null;
  regional_data: any;
}

interface AccountsResponse {
  accounts: Account[];
  data: Account[];
  page: {
    number: number;
    size: number;
    total_elements: number;
    total_pages: number;
  };
  type: string;
}

const BankAccounts: React.FC = () => {
  const { entityId } = useParams<{ entityId: string }>();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccounts();
  }, [entityId]);

  const fetchAccounts = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get<{ accounts: Account[] }>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections/${entityId}/accounts`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccounts(response.data.accounts || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setLoading(false);
    }
  };

  const navigateToAccountProfile = (account: Account) => {
    navigate(`/accounts/${account.account_id}`, { state: { entityId } });
  };

  if (loading) {
    return <div>Loading accounts...</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="accounts table">
        <TableHead>
          <TableRow>
            <TableCell>Account Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Account ID</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => (
            <TableRow key={account.account_id}>
              <TableCell component="th" scope="row">
                {account.nickname}
              </TableCell>
              <TableCell>{account.description}</TableCell>
              <TableCell>{account.account_id}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigateToAccountProfile(account)}
                >
                  View Account
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BankAccounts;
