import React, { useEffect, useRef, useState } from 'react'
import LandingMenu from '../landing/LandingMenu'
import WorkspaceInfoIcon from '../../assets/WorkspaceInfo.svg'
import CreditCard from '../../assets/CreditCard.svg'
import Users from '../../assets/Users.svg'
import Gray from '../../assets/Gray.svg'
import '../../css/workspaceInfo.css'
import axios from 'axios'
import Alert from '../shapes/Alert'
import { useNavigate, useParams } from 'react-router-dom';
import UserCircle from '../auth/UserCircle'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import WorkspaceIconPanel from './WorkspaceIconPanel'
import '../../css/BankAccount.css'
import AlawalLogo from '../../assets/BankAccounts/AlawalLogo.png'
import ModalComponent from '../shapes/ModalComponent'
import DinoLoading from '../../assets/gif/DinoLoading.gif'
import { Workspace } from '../../types/Workspace'
const token = localStorage.getItem('token');
const user = localStorage.getItem('user');
const userId = user ? JSON.parse(user).id : '';
const userName = user ? JSON.parse(user).name : '';
const UserShortName = userName ? userName.substring(0, 2).toUpperCase() : '';
interface BankAccountInterface {
  id: string;
  name: string;
  logo_path: string;
  icon_path: string;
}

const BankAccount = () => {
  const { uniCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [snippet, setSnippet] = useState('');
  const [description, setDescription] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [workspaceId, setWorkspaceId] = useState<number>();
  const [imagePath, setImagePath] = useState<string>();
  const [imageChanged, setImageChanged] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccountInterface[]>();
  const [allBankAccounts, setallBankAccounts] = useState<BankAccountInterface[]>();
  const [filterdBanks, setFilterdBanks] = useState<BankAccountInterface[]>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>({
    id: -1,
    name: '',
    snippet: '',
    description: '',
    uniCode: '',
    image: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [bankName, setBankName] = useState('');

  const mainFlexContainer = useRef<HTMLDivElement>(null);
  const formSide = useRef<HTMLDivElement>(null);
  const accountNameRef = useRef<HTMLInputElement>(null)
  const accountNumberRef = useRef<HTMLInputElement>(null)
  const ibanRef = useRef<HTMLInputElement>(null)
  const swiftCodeRef = useRef<HTMLInputElement>(null)
  const bankAddressRef = useRef<HTMLInputElement>(null)

  const pages = {
    'Workspace Info': [`/workspace-info/${uniCode}`, WorkspaceInfoIcon],
    'Collaborator': ['/collaborator/' + uniCode, Users],
    'Plan and Billing': ['', CreditCard],
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowModal(false);
  //   }, 100);
  // }, [showModal]);

  const getInitialData = async (token: string | null, id: string) => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-banks-account/${uniCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBankAccounts(response.data.files);
      setallBankAccounts(response.data.all_banks);
      setSelectedWorkspace(response.data.workspace);
      // filter the allbankAccounts to remove the banks that have been added
      const filteredBanks = response.data.all_banks.filter((bank: any) => {
        return !response.data.files.some((addedBank: any, index) => {
          return addedBank[index].name === bank.name;
        });
      });
    } catch (error) {
      console.error('Error fetching workspace:', error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmitBankInfo = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // console.log(e.currentTarget);
    const formData = new FormData(e.currentTarget);
    if (selectedWorkspace) {
      formData.append('workspace_id', selectedWorkspace.id.toString());
    }
    // Log each key-value pair from FormData
    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/save-bank-account-info`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      setAlertBody('Bank Account Info saved successfully');
      setAlertStatus('success');
      setShowAlert(true);
    } catch (error) {
      console.error('Error saving bank account info:', error);
      setShowAlert(true);
      setAlertBody('Error saving bank account info');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setLoading(false);
    }
  }

  const fetchBankAccountInfo = async (e: React.MouseEvent<HTMLDivElement>) => {
    setLoading(true);
    try {
      const bankName = e.currentTarget?.querySelector('.bank-name')?.textContent || '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-bank-account-info/${uniCode}/${bankName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (accountNameRef.current && accountNumberRef.current && ibanRef.current && swiftCodeRef.current && bankAddressRef.current) {
        accountNameRef.current.value = response.data.bank_account.account_name;
        accountNumberRef.current.value = response.data.bank_account.account_number;
        ibanRef.current.value = response.data.bank_account.iban;
        swiftCodeRef.current.value = response.data.bank_account.swift_code;
        bankAddressRef.current.value = response.data.bank_account.bank_address;
      }
    } catch (error) {
      console.error('Error fetching workspace:', error);
    }
    setLoading(false);
  }

  const handleShowFormSide = (e: React.MouseEvent<HTMLDivElement>, status: string) => {
    // console.clear();
    console.log("status: " + status);
    if (status === 'selected') {
      const bankCells = document.querySelectorAll('.bank-cell');
      bankCells.forEach((cell) => {
        if (cell !== e.currentTarget) {
          cell.classList.remove('selected-bank');
        }
      });
      e.currentTarget.classList.add('selected-bank');

      if (mainFlexContainer.current && formSide.current) {
        if (accountNameRef.current && accountNumberRef.current && ibanRef.current && swiftCodeRef.current && bankAddressRef.current) {
          accountNameRef.current.value = '';
          accountNumberRef.current.value = '';
          ibanRef.current.value = '';
          swiftCodeRef.current.value = '';
          bankAddressRef.current.value = '';
        }
        mainFlexContainer.current.style.width = '100%';
        formSide.current.classList.remove('overnight');
        const bankName = e.currentTarget?.querySelector('.bank-name')?.textContent || '';
        const bankAccountForm = document.getElementById('bank-account-form');
        if (bankAccountForm) {
          (bankAccountForm.querySelector('input[name="bank_name"]') as HTMLInputElement).value = bankName;
        }
        fetchBankAccountInfo(e)
      }
    } else {
      setBankName(e.currentTarget?.querySelector('.bank-name')?.textContent || '');
      setShowModal(true);
    }
  }

  const handleAction = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/add-bank-account/${uniCode}`;
      const data = new FormData();
      data.append('bank_name', bankName)
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      console.clear();
      console.log('>>>>>>', response.data);
      setAlertBody('Bank added successfully');
      setAlertStatus('success');
      setShowAlert(true);

      const addBankElement = document.getElementById('add-bank');
      if (addBankElement) {
        addBankElement.innerHTML = 'ADD A BANK';
      }

      // change the label of the button to "ADD A BANK"
      // hide the list of banks and show the list of selected banks
      console.clear();
      const bankNamePTag = document.querySelectorAll('.bank-name') as NodeListOf<HTMLParagraphElement>;
      bankNamePTag.forEach((name) => {
        if (name.textContent === bankName) {
          const bankCell = name.parentElement;
          if (bankCell) {
            bankCell.classList.add('overnight');
            // bankCell.addEventListener('click', (event) => handleShowFormSide(event as unknown as React.MouseEvent<HTMLDivElement>, 'selected'));
            // Clone the element to remove all previous event listeners
            const newBankCell = bankCell.cloneNode(true) as HTMLDivElement;

            // Replace the original element with the cloned element
            bankCell.parentNode?.replaceChild(newBankCell, bankCell);

            // Add the new click event listener
            newBankCell.addEventListener('click', (event) => handleShowFormSide(event as unknown as React.MouseEvent<HTMLDivElement>, 'selected'));
            console.log('this line is after addEventListener 217');
          }
        }
      })
      const bankCells = document.querySelectorAll('.bank-cell');
      bankCells.forEach((cell) => {
        if (cell.classList.contains('overnight')) {
          cell.classList.remove('overnight');
        } else {
          cell.classList.add('overnight');
        }
      });
    } catch (error) {
      console.error('Error performing action:', error);
      setShowAlert(true);
      setAlertBody('Error performing action');
      setAlertStatus('error');

    } finally {
      setShowModal(false);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      setLoading(false);
    }
  };


  const handleShowAllBanks = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    if (target.innerHTML === 'ADD A BANK') {
      target.innerHTML = 'HIDE BANKS';
    } else {
      target.innerHTML = 'ADD A BANK'
    }
    const bankCells = document.querySelectorAll('.bank-cell');
    bankCells.forEach((cell) => {
      if (cell.classList.contains('overnight')) {
        cell.classList.remove('overnight');
      } else {
        cell.classList.add('overnight');
      }
    });
  }

  useEffect(() => {
    if (mainFlexContainer.current) {
      // mainFlexContainer.current.style.width = '25rem';
    }
    if (uniCode) {
      getInitialData(token, uniCode);
    }
  }, []);
  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      <LandingMenu pages={pages} hidePopup={true} hidePanelContents={false} pageProp="WORKSPACE INFO" selectedWorkspace={selectedWorkspace} />
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>Workspace info</h3>
            <p>Configure your workspace settings</p>
          </div>
          <UserCircle />
        </div>

        <div id='content-container' className="position-relative">
          {
            <>
              <p className={`bold-text ${loading ? '' : 'overnight'}`} style={
                {
                  'color': '#94ddf3',
                  'display': 'block',
                  'fontSize': '2rem',
                  'position': 'absolute',
                  'marginBottom': '.5rem'
                }}>
                Loading...
              </p>
            </>
          }

          {
            uniCode
              ?
              <WorkspaceIconPanel page="workspace" uniCode={uniCode} />
              :
              ''
          }
          <div className="form-box rounded-radius w-50 m-t-5 pd-1 right-panel">
            <button type='button' id='add-bank' className='clickable pointer m-l-1 m-b-1' onClick={handleShowAllBanks}>ADD A BANK</button>
            <div className="bank-account-container flex-container" ref={mainFlexContainer}>
              <div className="banks-container flex-container vertical-flex flex-start flex-1-4">

                {
                  allBankAccounts && allBankAccounts.length ? allBankAccounts.map((bank: any) => {
                    let matchedBank = false;

                    bankAccounts?.forEach((selectedBank: BankAccountInterface, index: number) => {
                      if (selectedBank[index].name.toUpperCase() === bank.name.toUpperCase()) {
                        matchedBank = true;
                      }
                    });
                    if (matchedBank) {
                      return (
                        <div className="bank-cell flex-container clickable pointer" key={bank.name} onClick={(e) => handleShowFormSide(e, 'selected')}>
                          <p className="bank-name eles">
                            {bank.name.toUpperCase()}
                          </p>
                          <img src={bank.logo_path} className='bank-logo' alt="company-logo" />
                        </div>
                      );
                    } else {
                      return (
                        <div className="bank-cell flex-container clickable pointer overnight" key={bank.name} onClick={(e) => handleShowFormSide(e, 'notSelected')}>
                          <p className="bank-name if">
                            {bank.name.toUpperCase()}
                          </p>
                          <img src={bank.logo_path} className='bank-logo' alt="company-logo" />
                        </div>
                      );
                    }
                  }) :
                    <div className="bank-cell flex-container">
                      <div className="bank-name text-gray">BANK NAME</div>
                      <div className="bank-logo"><img src={Gray} alt="company-logo" /></div>
                    </div>
                }
              </div>
              <div className="flex-3-4 main-form-container overnight" ref={formSide}>
                <form id='bank-account-form' onSubmit={handleSubmitBankInfo}>
                  <div className="flex-container vertical-flex form-container">
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        BANK NAME
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="bank_name" readOnly />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        Account Name
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="account_name" ref={accountNameRef} />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        Account Number
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="account_number" ref={accountNumberRef} />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        IBAN
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="iban" ref={ibanRef} />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        Swift Code
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="swift_code" ref={swiftCodeRef} />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-1-4">
                        Bank Address
                      </div>
                      <div className="input-shape flex-3-4">
                        <input type="text" name="bank_address" ref={bankAddressRef} />
                      </div>
                    </div>
                    <div className="flex-container w-100 bank-account-row">
                      <div className="input-shape w-100 flex-3-4 opacity-0">
                        Bank Address
                      </div>
                      <div className="flex-1-4">
                        <input type="submit" value="Save" className='btn btn-dark m-t-1 float-right sejel-button clickable' />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ModalComponent
        logoutStyle='from-menu'
        content={`CancelRequest`}
        classProp={'role_image delete-user clickable pointer modal-icon overnight'}
        message={'Are you sure you want to add this bank?'}
        action={handleAction}
        actionName='cancel'
        label="Add A Bank"
        showModal={showModal}
      />
    </div>
  )
}

export default BankAccount