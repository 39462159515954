import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';

interface DayDropdownProps {
  items: string[];
  selectedItem?: string;
  defaultSelectedItem?: string;
  onChange: (selectedItem: string) => void;
  otherClasses?: string;
}

const Dropdown: React.FC<DayDropdownProps> = ({ items, selectedItem, defaultSelectedItem = '', onChange, otherClasses }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<string | null>(selectedItem || defaultSelectedItem);

  useEffect(() => {
    setCurrentItem(selectedItem || defaultSelectedItem);
  }, [selectedItem]);

  const handleSelect = (item: string) => {
    setCurrentItem(item);
    onChange(item);
    setIsOpen(false);
  };

  const optionBgColorLight = 'bg-violet-300';
  const optionBgColorDark = 'bg-violet-400';

  return (
    <div className="relative inline-block text-left w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`min-w-fit bg-gray-200 rounded-2xl shadow-sm px-2 py-2 text-gray-700 text-lg h-10 font-medium focus:outline-none ${otherClasses}`}
      >

        {/* {currentItem && currentItem !== 'ADDITIONAL' ? `DAY ${currentItem}` : 'ADDITIONAL'} */}
        {currentItem}
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 min-w-fit bg-transparent rounded-md">
          <ul className="py-1 bg-transparent">
            {items.map((item, index) => (
              <li
                key={index}
                onClick={() => handleSelect(item)}
                className={`cursor-pointer px-2 py-2 text-white ${index % 2 === 0 ? optionBgColorLight : optionBgColorDark
                  } rounded-2xl mb-1 hover:bg-opacity-75`}
              >
                {/* {item !== 'ADDITIONAL' ? `DAY ${item}` : item} */}
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
