import React, { useEffect, useRef, useState } from 'react'
import LandingMenu from './landing/LandingMenu'
import WorkspaceInfoIcon from './../assets/WorkspaceInfo.svg'
import CreditCard from './../assets/CreditCard.svg'
import Users from './../assets/Users.svg'
import '../css/workspaceInfo.css'
import ImageSelector from './shapes/ImageSelector'
import axios from 'axios'
import Alert from './shapes/Alert'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { url } from 'inspector'
import UserCircle from './auth/UserCircle'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { register } from '../services/api'
import { useDispatch } from 'react-redux'
import { setUser } from '../features/users/UserSlice'
const token = localStorage.getItem('token');


interface Props {
  registerProp?: boolean;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const selectedWorkspace = {
  id: 0,
  name: '',
  snippet: '',
  description: '',
  uniCode: '',
  image: '',
}
const UpdateUser: React.FC<Props> = ({ registerProp }) => {
  const { userId } = useParams();
  const { workspaceId } = useParams();
  const [requiredInput, setRequiredInput] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alertBody, setAlertBody] = useState('');
  const [alertStatus, setAlertStatus] = useState('');
  const [tempToken, setTempToken] = useState<string>();
  const [imagePath, setImagePath] = useState<string>();
  const [showAlert, setShowAlert] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const inputId = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const query = useQuery();
  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const [pages, setPages] = useState<any>({});
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fullName = document.querySelector('input[name="name"]') as HTMLInputElement;
    const password = document.querySelector('input[name="password"]') as HTMLInputElement;
    const confirmPassword = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement;
    const submitButton = document.querySelector('input[type="submit"]') as HTMLInputElement;
    const { name, value } = e.target;
    if (name === 'name') {
      setFullName(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    // if (fullName.value && password.value && confirmPassword.value) {
    //   submitButton.classList.remove('inactive');
    // } else {
    //   submitButton.classList.add('inactive');
    // }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fullName = document.querySelector('input[name="name"]') as HTMLInputElement;
    const password = document.querySelector('input[name="password"]') as HTMLInputElement;
    const confirmPassword = document.querySelector('input[name="confirmPassword"]') as HTMLInputElement;
    const email = document.querySelector('input[name="email"]') as HTMLInputElement;
    const id = document.querySelector('input[name="id"]') as HTMLInputElement;
    const formData = new FormData();
    if (password.value !== confirmPassword.value) {
      setAlertBody('Passwords do not match');
      setAlertStatus('error');
      setShowAlert(true);
      return;
    }

    formData.append('id', id.value);
    formData.append('name', fullName.value);
    formData.append('email', email.value);
    formData.append('password', password.value);
    if (workspaceId) formData.append('workspaceId', workspaceId)
    else console.log('workspaceId is undefined');
    formData.append('password_confirmation', confirmPassword.value);
    formData.append('tempToken', (document.querySelector('input[name="tempToken"]') as HTMLInputElement).value);

    if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0]) {
      formData.append('profile_image', fileInputRef.current.files[0]);
    }

    saveUser(token, formData);

  };

  const saveUser = async (token: string | null, data: FormData) => {
    try {
      let url = '';
      if (registerProp) {
        url = `${process.env.REACT_APP_API_URL}/register`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/user/${collaboratorId}`;
      }
      console.clear();
      console.log(url)
      // return
      setLoading(true)
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowAlert(true)
      setAlertBody('User saved successfully');
      setAlertStatus('success');
      if (tempToken) {
        localStorage.setItem('token', response.data.token);
        // localStorage.setItem('user', JSON.stringify(response.data.user));
        // if (registerProp) {
        console.clear();
        console.log(response.data.user);
        dispatch(setUser(response.data.user));
        // }
        if (!registerProp) navigate('/');
      }
    } catch (error) {
      // console.clear();
      console.error(error);
      if (registerProp) {
        setAlertBody('Registration failed');
      } else {
        setAlertBody('User did not updated successfully');
      }
      setAlertStatus('error');
      setShowAlert(true)
    } finally {
      setTimeout(() => {
        setShowAlert(false)
      }, 5000);
      setLoading(false);
    }
  };

  const getUser = async (token: string | null, id: string) => {
    let url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/user/${id}`;
    if (!id) {
      url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/user/${id}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setFullName(response.data.name);
    setEmail(response.data.email);
    if (response.data.profile_image) {
      setImagePath(process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + response.data.profile_image);
    }
  }

  const getUserByTempToken = async (token: string | null, tempToken: string) => {
    if (!tempToken) return;
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-user-by-tempToken/${tempToken}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.image) {
        setImagePath(`${process.env.REACT_APP_API_URL}/storage${response.data.profile_image}`);
      }
      setFullName("");
      setEmail(response.data.user.email);
      if (inputId.current) inputId.current.value = response.data.user.id;
      setCollaboratorId(response.data.user.id);
    } catch (error) {
      navigate('/token-expired');
      console.error(error);
    }
  }

  // useEffect(() => {
  //   console.clear();
  //   console.log(user.id, userId, user.id === parseInt(userId as string));
  //   if (user.id !== parseInt(userId as string)) {
  //     navigate('/');
  //   }
  // }, []);

  useEffect(() => {
    // console.clear();
    console.log(workspaceId);
  }, [workspaceId]);

  useEffect(() => {
    const token = query.get('tempToken');
    if (token) {
      setTempToken(token);
      setRequiredInput(true);
    }
  }, [query]);

  useEffect(() => {
    try {
      getUserByTempToken(token, tempToken as string);
    } catch (error) {
      console.error(error);
    }
  }, [tempToken]);
  useEffect(() => {
    if (userId) {
      getUser(token, userId);
    } else {
      console.log('NO ID');
    }
  }, [userId]);

  return (
    <div className="main-container flex-container">
      <div>
        {showAlert ? <Alert body={alertBody} status={alertStatus} /> : ""}
      </div>
      {tempToken ? "" : <LandingMenu pages={pages} hidePopup={true} hidePanelContents={true} pageProp="collaborator" selectedWorkspace={selectedWorkspace} />}
      <div id="workspace-info-side" className='main-side flex-10'>
        <div id="user-section" className="flex-container flex-space-between">
          <div id="title">
            <h3 className='bold-text'>User info</h3>
            <p>{registerProp ? 'Create your account' : 'Change your info'}</p>
          </div>
          <UserCircle />
        </div>
        <div className="form-box bg-white rounded-radius w-50 m-t-5 pd-1">
          <form id='workspace-form' method="post" onSubmit={handleSubmit}>
            <input type="hidden" name="id" id="id" placeholder="id" value={userId} ref={inputId} />
            <input type="hidden" name="workspaceId" id="workspaceId" placeholder="workspaceId" value={workspaceId ? workspaceId : ''} ref={inputId} />
            <input type="hidden" name="tempToken" id="tempToken" placeholder='tempToken' value={tempToken} />
            <input type="text" onChange={handleChange} value={fullName} name='name' placeholder='Name' className='workspace-input rounded-radius' autoComplete="off" required={requiredInput} />
            {
              registerProp ?
                <input type="email" name='email' placeholder='Email' className='workspace-input rounded-radius' required={requiredInput} />
                :
                <input type="email" value={email} name='email' placeholder='Email' className={`inactive-input workspace-input rounded-radius ${tempToken ? 'overnight' : ''}`} readOnly required={requiredInput} />
            }
            <input type="password" onChange={handleChange} value={password} name='password' placeholder='Password' className='workspace-input rounded-radius' required={requiredInput} />
            <input type="password" onChange={handleChange} value={confirmPassword} name='confirmPassword' placeholder='Confirm Password' className='workspace-input rounded-radius' required={requiredInput} />
            <ImageSelector initialImageUrl={imagePath || 'https://via.placeholder.com/150'} onImageChange={(newImageUrl) => console.log(newImageUrl)} fileInputRef={fileInputRef} />
            <input type="submit" value="Save" className='submit-button rounded-radius m-t-1' />
            {/* <p className="bold-text" style={{ 'color': '#94ddf3' }}>loading...</p> */}
            {
              loading ? <p className="bold-text" style={
                {
                  'color': '#94ddf3',
                  'display': 'inline-block',
                  'marginLeft': '1rem',
                  'fontSize': '2rem',
                  'top': '4px',
                  'position': 'relative',
                }}>
                Loading...
              </p> : ""
            }
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateUser