import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import LeanConnect from '../Lean/LeanConnect';

interface Permissions {
  identity: boolean;
  accounts: boolean;
  balance: boolean;
  transactions: boolean;
  identities: boolean;
  scheduled_payments: boolean;
  standing_orders: boolean;
  direct_debits: boolean;
  beneficiaries: boolean;
}

interface BankConnection {
  id: number;
  entity_id: string;
  user_id: number;
  bank_identifier: string;
  permissions: Permissions;
  bank_type: string;
  consents: any;
  created_at: string;
  updated_at: string;
}

const ConnectedBanksList: React.FC = () => {
  const [bankConnections, setBankConnections] = useState<BankConnection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBankConnections();
  }, []);

  const fetchBankConnections = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get<BankConnection[]>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBankConnections(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank connections:', error);
      setLoading(false);
    }
  };

  const handleDeleteBankConnection = async (entityId: string) => {
    if (window.confirm('Are you sure you want to disconnect this bank?')) {
      const token = localStorage.getItem('token');

      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections/${entityId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            data: {
              reason: 'User requested disconnection',
            },
          }
        );
        // Remove the bank connection from the list
        setBankConnections((prev) =>
          prev.filter((connection) => connection.entity_id !== entityId)
        );
      } catch (error) {
        console.error('Error deleting bank connection:', error);
        alert('Failed to disconnect the bank.');
      }
    }
  };

  const navigateToAccounts = (bankConnection: BankConnection) => {
    navigate(`/bank-connections/${bankConnection.entity_id}/accounts`);
    // navigate(`/banks/${bankConnection.entity_id}/accounts`);
  };

  if (loading) {
    return <div>Loading connected banks...</div>;
  }

  return (
    <>
      <LeanConnect />
      
      <TableContainer component={Paper}>
        <Table aria-label="connected banks table">
          <TableHead>
            <TableRow>
              <TableCell>Bank Identifier</TableCell>
              <TableCell>Bank Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankConnections.map((bankConnection) => (
              <TableRow key={bankConnection.entity_id}>
                <TableCell component="th" scope="row">
                  {bankConnection.bank_identifier}
                </TableCell>
                <TableCell>{bankConnection.bank_type}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigateToAccounts(bankConnection)}
                    style={{ marginRight: '8px' }}
                  >
                    View Accounts
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteBankConnection(bankConnection.entity_id)}
                  >
                    Delete Bank Connection
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ConnectedBanksList;
