import React, { act, useEffect, useRef, useState } from 'react'
import "./../css/Dashboard.css"
// import "./../css/Main.css"
import { AppDispatch, RootState } from '../store/index';
import { loadUserFromStorage } from '../features/users/UserSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Import from '../assets/Import.svg';
import Invoice from '../assets/Invoice.svg';
import WavingHand from '../assets/WavingHand.svg';
import rajhi from '../assets/blue.svg';
import hsbc from '../assets/red.svg';
import black from '../assets/black.svg';
import UpperArrow from '../assets/UpperArrow.svg';
import DownArrow from '../assets/DownArrow.svg';
import Gray from '../assets/Gray.svg';
import axios from 'axios';
import { addCommaAndSeparator, getCurrentDate, getDay, getMonthAbbreviation, getYear } from '../utilities/helper';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Workspace } from '../types/Workspace';

interface CustomerData {
  id: string;
  fields: {
    'Trip/Contact Name': string;
    'INVOICE DATE': string;
    [key: string]: any;
  };

}
export const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.userState);
  const { uniCode } = useParams();
  const [inactivePage, setInactivePage] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [incoming, setIncoming] = useState(0);
  const [outgoing, setOutgoing] = useState(0);
  const [clientInvoice, setClientInvoice] = useState(0);
  const [clientReceivables, setClientReceivables] = useState(0);
  const [totalClientAmount, setTotalClientAmount] = useState(0);
  const [totalClientReceivables, setTotalClientReceivables] = useState(0);
  const [recentActivities, setRecentActivities] = useState([]);
  const [customerActivities, setCustomerActivities] = useState([]);
  const [bankAccount, setBankAccount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [keyFigureDate, setKeyFigureDate] = useState('');
  const [currency, setCurrency] = useState('CUR');
  const data = [];
  const token = localStorage.getItem('token');
  const initialFetch = useRef(true);
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>();
  const fetchAccountBalance = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getDashboardData/${uniCode}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data) {
        setAccountBalance(response.data.accountBalance);
        setIncoming(response.data.incoming);
        setRecentActivities(response.data.recentActivities);
        setClientInvoice(response.data.amountPerCurrentMonth);
        setClientReceivables(response.data.clientReceivables);
        setCustomerActivities(response.data.customerActivities);
        setSelectedWorkspace(response.data.selectedWorkspace);
        setTotalClientAmount(response.data.totalClientAmount);
        setTotalClientReceivables(response.data.totalClientReceivables);
        setOutgoing(response.data.outgoing);
        setBankAccount(response.data.bankAccounts);
        // console.log("response.data", response.data);
        setCurrency(response.data['recentActivities'][0]['CUR']);
        setLoading(false);
      } else {
        console.error("No data from account balance");
      }
    }
    catch (err) {
      console.log("error", err);
    }
  }

  useEffect(() => {
    if (uniCode && uniCode != 'R6AmEkD4H') {
      setInactivePage(true);
    }
  }, [uniCode]);

  useEffect(() => {
    setKeyFigureDate('2024 Key Figures')
    if (initialFetch.current) {
      // setKeyFigureDate(getMonthAbbreviation(getCurrentDate()) + '.' + getCurrentDate().substring(2, 4) + ' Key Figures')
      initialFetch.current = false;
      // fetchAccountBalance();
      dispatch(loadUserFromStorage());
    }
  }, []);

  useEffect(() => {
    if (uniCode && uniCode === 'R6AmEkD4H') {
      fetchAccountBalance();
    }
  }, [uniCode]);

  return (
    <>
      <div id="main_side">
        <div id="header-1" className='flex-container flex-space-between pl-1 p-t-1'>
          <div className='flex-container flex-start'>
            <img id='wavingHand' src={WavingHand} alt="WavingHand" />
            <h1 className='bold-text upper-case'>hayyak, {user.name} !</h1>
          </div>
          <div className='flex-container flex-start'>
            <h1 className='bold-text upper-case m-r-1'>{selectedWorkspace && selectedWorkspace.name}</h1>
            {/* <h2>{selectedWorkspace ? selectedWorkspace.image : 'wait'}</h2> */}
            <img id='wavingHand' src={selectedWorkspace && selectedWorkspace.image ? process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + selectedWorkspace?.image : Gray} alt="Logo" />
          </div>
          <div style={{ 'opacity': 0 }}>
            <h1 className='bold-text upper-case'>hayyak, {user.name} !</h1>
          </div>
        </div>
        <div id="header-2" className=''>
          <div className="box w-100">
            <div className="flex-container flex-small-gap">
              <div className="flex-2-3 flex-container flex-start p-v-0">
                {/* <div className="empty-box dotted-box flex-1-3 rounded-radius flex-container flex-center p-v-1">
                  TEXT
                </div> */}
                <div className="empty-box box dotted-box flex-1-3 rounded-radius flex-container p-v-1 flex-start">
                  <img src={Invoice} alt="invoice" className='standard-icon' />
                  <div className='m-l-1'>
                    <Link to={`/create-invoice/${uniCode}`} className='text-decoration-none'>INVOICE CUSTOMER</Link>
                  </div>
                </div>
                <div className="empty-box box dotted-box flex-1-3 rounded-radius flex-container p-v-1 m-l-3">
                  <img src={Import} alt="import" className='standard-icon' />
                  <div className='m-l-1'>
                    IMPORT SUPPLIER INVOICE
                  </div>
                </div>
              </div>
              <div className="flex-1-3 flex-container p-h-1">
                <div className="box bg-pink flex-2-4 rounded-radius align-center m-r-1">RUNWAY - ---</div>
                <div className="box bg-green flex-2-4 rounded-radius align-center margin-10-percent">NWC - ---</div>
              </div>
            </div>
          </div>
        </div>
        <div id="block-1" className='block m-t-2'>
          <h3 className='pl-1 bold-text'>{keyFigureDate}</h3>
          <div className="box w-100">
            <div className='flex-container flex-small-gap align-stretch'>
              <div className="left-side flex-2-3 black-border rounded-radius">
                <div className="upper-part rounded-radius">
                  <div className="flex-container flex-start p-h-1 p-v-1">
                    <div className="">
                      <p>
                        ACCOUNT BALANCE
                      </p>
                      <h3 className='bold-text'>
                        {currency} {inactivePage ? '-' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(accountBalance)}
                      </h3>
                    </div>
                    <div className="">
                      <div className="flex-container flex-start padding-half">
                        {/* <div><img src={rajhi} alt="rajhi-bank" className='rajhi-icon' /></div> */}
                        {bankAccount.map((bank: any, index: number) => {
                          return (
                            <div key={index}>
                              <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + bank.icon_path} alt={bank.icon_path} className='image-w-3 m-r-1' />
                            </div>
                          )
                        })}
                        {/* <div><img src={black} alt="black-icon" className='black-icon' /></div> */}
                        {/* <div><img src={hsbc} alt="hsbc-icon" className='hsbc-icon' /></div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lower-part flex-container flex-start w-100">
                  <div className="flex-2-2 left-side half-width flex-container flex-start">
                    <span className='float-left'>
                      <p>INCOMING</ p>
                      <img src={UpperArrow} alt="upper-arrow" className='bg-light-green rounded-radius' />
                    </span>
                    <h3 className='incoming-price'>
                      {currency} {inactivePage ? '-' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(incoming)}
                    </h3>
                  </div>
                  <div className="flex-2-2 left-side half-width flex-container flex-start">
                    <span className='float-left'>
                      <p>OUTGOING</p>
                      <img src={DownArrow} alt="down-arrow" className='bg-pink rounded-radius' />
                    </span>
                    <h3 className='incoming-price'>
                      {currency} {inactivePage ? '-' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(outgoing)}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="right-side flex-1-3 black-border rounded-radius">
                <div className="grid-container">
                  <div className="pl-4 p-t-2 grid-item">
                    <p>CLIENT INVOICING</p>
                    <h3>{currency} - {inactivePage ? '---' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(clientInvoice)}</h3>
                  </div>
                  <div className="p-t-2 grid-item">
                    <p>CLIENT RECEIVABLES</p>
                    <h3>{currency} - {inactivePage ? '---' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(clientReceivables)}</h3>
                  </div>
                  <div className="pl-4 p-b-2 grid-item">
                    <p>SUPPLIER INVOICING</p>
                    <h3>{currency} - ---</h3>
                  </div>
                  <div className="p-b-2 grid-item">
                    <p>SUPPLIER PAYABLES</p>
                    <h3>{currency} - ---</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="block-2" className='block m-t-2'>
          <h3 className='pl-1 bold-text'>ACTIONS - TO DO - MISSING ITEMS</h3>
          <div className="box w-100">
            <div className="flex-container black-border rounded-radius flex-space-around p-t-1">
              <div className=''>
                <p>UNDEFINED ENTRIES</p>
                <p className="bold-text margin-y-1">{currency} - ---</p>
                <div className="align-center box small-button black-bg text-white box-size-m rounded-radius margin-bottom-2">DEFINE</div>
              </div>
              <div className=''>
                <p>OVERDUE CUSTOMER INVOICES</p>
                <p className="bold-text margin-y-1">{currency} - {inactivePage ? '---' : loading ? <span style={{ color: '#94ddf3' }}>loading</span> : addCommaAndSeparator(totalClientReceivables)}</p>
                <div className="align-center box small-button black-bg text-white box-size-m rounded-radius margin-bottom-2">SEND REMINDER</div>
              </div>
              <div className=''>
                <p>LATE SUPPLIER INVOICES</p>
                <p className="bold-text margin-y-1">{currency} - ---</p>
                <div className="align-center box small-button black-bg text-white box-size-m rounded-radius margin-bottom-2">SETTLE</div>
              </div>
              <div className=''>
                <p>MISSING VAT SUPPLIERS INVOICES</p>
                <p className="bold-text margin-y-1">{currency} - ---</p>
                <div className="align-center box small-button black-bg text-white box-size-m rounded-radius margin-bottom-2">SEND REMINDER</div>
              </div>
            </div>
          </div>
        </div>
        <div id="block-3" className='block recent_activities m-t-2'>
          <h3 className='pl-1 bold-text'>RECENT ACTIVITIES</h3>
          <div className="box">
            <div className="black-border rounded-radius flex-space-around">
              <div className="flex-container p-1-p W-100">
                <div id="transaction" className='m-l-3 mt-2 mb-2 flex-1-3'>
                  <p className="bold-text">
                    TRANSACTION
                  </p>
                </div>
                <div id="customer-invoices" className='m-l-3 mt-2 mb-2 flex-1-3'>
                  <p className="bold-text">
                    CUSTOMER INVOICES
                  </p>
                </div>
                <div id="supplier-invoices" className='m-l-3 mt-2 mb-2 flex-1-3'>
                  <p className="bold-text">
                    SUPPLIER INVOICES
                  </p>
                </div>
              </div>
              {inactivePage ? '' : loading
                ?
                <div className="flex-container p-1-p W-100">
                  <div id="transaction" className='m-l-3 mt-2 mb-2 flex-1-3'>
                    <p className="bold-text" style={{ 'color': '#94ddf3' }}>
                      Loading...
                    </p>
                  </div>
                  <div id="customer-invoices" className='m-l-3 mt-2 mb-2 flex-1-3'>
                    <p className="bold-text" style={{ 'color': '#94ddf3' }}>
                      Loading...
                    </p>
                  </div>
                  <div id="supplier-invoices" className='m-l-3 mt-2 mb-2 flex-1-3'>
                    <p className="bold-text" style={{ 'color': '#94ddf3' }}>
                      Loading...
                    </p>
                  </div>
                </div>
                :
                recentActivities.map((activity: any, index: number) => {
                  return (

                    <div key={index} className="activities flex-container p-1-p w-100 pr-5-p">
                      <div id="" className={`transaction m-l-1 flex-container pd-1 flex-1-3 flex-start rounded-radius ${activity.AMOUNT > 0 ? 'bg-positive' : 'bg-negative'}`}>
                        <div className="flex-3-4">
                          <span className=''>
                            <img src={black} alt="black-icon" className='black-icon float-left m-t-1 m-r-1' />
                          </span>
                          <div className=''>
                            <p className='margin-5-p'>
                              <span>
                                {activity['SOURCE DETAIL']}
                              </span>
                              &nbsp;-&nbsp;
                              <span>{activity['Bank Description'] && activity['Bank Description'].length > 40 ? activity['Bank Description'].substring(0, 40) + "..." : activity['Bank Description']}</span>
                            </p>
                            <p className='margin-5-p'>
                              {getMonthAbbreviation(activity['DATE'])}-{getDay(activity['DATE'])}-{getYear(activity['DATE'])}
                            </p>
                          </div>
                        </div>
                        <div className="flex-1-4">
                          <div className='flex-container-vertical pl-1'>
                            <p>
                              {currency} {addCommaAndSeparator(activity.AMOUNT)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div id="" className='customer-invoice m-l-1 bg-gray flex-container pd-1 flex-1-3 flex-start rounded-radius'>
                        <div className='flex-3-4'>
                          <p className='margin-5-p'>
                            {customerActivities[index]['Trip/Contact Name'] ?? 'MISSING CLIENT INFO'}
                          </p>
                          <p className='margin-5-p'>
                            {getMonthAbbreviation(customerActivities[index]['INVOICE DATE'])}-{getDay(customerActivities[index]['INVOICE DATE'])}-{getYear(customerActivities[index]['INVOICE DATE'])}
                          </p>
                        </div>
                        <div className='flex-container-vertical flex-1-4 pl-1'>
                          <p>
                            {currency} {addCommaAndSeparator(customerActivities[index]['TOTAL'] ?? 0)}
                          </p>
                        </div>
                      </div>

                      <div id="" className='m-l-1 bg-gray flex-container pd-1 flex-1-3 flex-start rounded-radius m-r-1'>
                        <div className='flex-3-4'>
                          <p className='margin-5-p'>
                            SUPPLIER DESCRIPTION
                          </p>
                          <p className='margin-5-p'>
                            MMM-DD-YYYY
                          </p>
                        </div>
                        <div className='flex-container-vertical pl-1 flex-1-4'>
                          <p>
                            {currency} - ---
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                }
                )
              }
              {/* {} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
